@use '../../00-variables/breakpoints' as breakpoints;
@use '../../01-mixins/mixins' as mixins;

/* ================
 BLOCK
================= */

.form-collection {
    --perforation-height: .5rem;
    --perforation-radius: calc(var(--card-border-radius) / 2);
    counter-reset: entryCount 0;

    position: relative;
    padding-block: 0 !important;

    & > .fieldset__legend {
        display: block;

        &:nth-last-child(4) {
            width: 100%;
            padding-block: calc((3.5rem - var(--target-size)) / 2);
            padding-inline: var(--card-padding);
            background: #FFFFFF;
            border-radius: var(--card-border-radius);
            box-shadow: var(--shadow-huge);

            .fieldset__heading {
                margin: 0;
            }
        }

        &:not(:nth-last-child(4)) {
            position: absolute;
            top: calc((3.5rem - var(--target-size)) / 2);
            padding-inline: var(--card-padding);
            z-index: 1;

            .safari & { // use this sparingly
                --translateY: calc((3.5rem - var(--target-size)) / 2);
                position: relative;
                top: 0;
                margin-block-end: calc(-1 * var(--target-size));
                transform: translateY(var(--translateY));
            }
        }
    }
}

/* ================
 ELEMENTS
================= */

.form-collection__entry {
    overflow: unset;
    position: relative;
    counter-increment: entryCount;
    margin-block-end: 0 !important;

    /* add counter to legend */
    > .fieldset__legend .fieldset__heading::after {
        content: counter(entryCount);
    }

    /* hide when collection is disabled */
    :disabled &:not(:first-of-type) {
        display: none;
    }

    /* tearing seam */
    &:not(:first-of-type) {
        margin-top: var(--perforation-height);
        border-top-left-radius: var(--perforation-radius);
        border-top-right-radius: var(--perforation-radius);

        &::before {
            content: '';
            position: absolute;
            bottom: 100%;
            transform: translateY(var(--card-padding));
            left: var(--perforation-radius);
            right: var(--perforation-radius);
            height: calc(var(--perforation-height) + 2 * var(--card-padding));
            background-image: linear-gradient(to bottom,
                #FFFFFF,
                #FFFFFF calc(50% - var(--perforation-height) / 2),
                transparent calc(50% - var(--perforation-height) / 2),
                transparent calc(50% + var(--perforation-height) / 2),
                #FFFFFF calc(50% + var(--perforation-height) / 2),
                #FFFFFF
            ), url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 30 100 40' id='svg'%3E%3Cmask id='mask'%3E%3Crect x='0' y='0' width='100' height='100' fill='white'/%3E%3Crect x='10' y='40' width='80' height='20' fill='black' rx='10'/%3E%3C/mask%3E%3Crect x='0' y='0' width='100' height='100' fill='white' mask='url(%23mask)'/%3E%3C/svg%3E");
            background-size: 100%, auto var(--perforation-height);
        }
    }

    &:not(:last-of-type) {
        border-bottom-left-radius: var(--perforation-radius);
        border-bottom-right-radius: var(--perforation-radius);
    }
}

.form-collection__ctas {
    display: grid;
    grid: auto / subgrid;
    gap: inherit;
}

/* ================
 MODIFIER(S)
================= */

.form-collection__entry:first-of-type {
    /* room for parent legend */
    padding-block-start: 3.5rem;

    &:disabled {
        padding-block-end: 0;
    }

    /* hide first child legend */
    > .fieldset__legend {
        @include mixins.sr-only;
    }

    /*  hide removal cta */
    .form-collection__cta--remove {
        display: none;
    }
}

@media (min-width: breakpoints.$lg) {
    .form-collection__cta--add {
        place-self: start;
    }

    .form-collection__cta--remove {
        place-self: end;
    }
}


