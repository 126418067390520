@use '../00-variables/breakpoints' as breakpoints;

/* ================
 BLOCK
================= */

.split-pane {
    display: grid;
    grid: auto / minmax(0, 2fr) minmax(0, var(--sidebar-width, 1fr));
    grid-template-areas: "main sidebar";
    gap: calc(var(--form-grid-gap) * 1.5);
}

/* ================
 ELEMENTS
================= */

.split-pane__main {
    grid-area: main;
    display: flex;
    flex-direction: column;
    gap: var(--form-grid-gap);
}

.split-pane__sidebar {
    grid-area: sidebar;
}

/* ================
 MODIFIER(S)
================= */

@media screen and (max-width: breakpoints.$md) {
    .split-pane {
        display: flex;
        flex-direction: column;
    }
}
