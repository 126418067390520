/* ================
 BLOCK
================= */

.status-indicator {
    --bg: var(--gray-46);
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-inline-start: 1ch;
    width: .75ex;
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: var(--bg);
    transition: background-color 150ms ease-in-out;

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: var(--target-size);
        aspect-ratio: inherit;
        border-radius: inherit;
    }
}

/* ================
 MODIFIER(S)
================= */

.status-indicator:not([data-status]) {
    display: none;
}

.status-indicator[data-status="ok"] {
    --bg: var(--green);
}

.status-indicator[data-status="error"] {
    --bg: var(--red);
}

.status-indicator[data-status="warning"] {
    --bg: var(--orange);
}

.status-indicator--no-margin {
    margin-inline-start: 0;
}
