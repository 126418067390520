/* ================
 BLOCK
================= */

.share {
    // empty for reference
}

/* ================
 ELEMENTS
================= */

.share__list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: var(--form-grid-gap);
}

.share__item {
    flex: 0 1 auto;
}

.share__button {
    width: 100%;
    font-weight: normal;
    justify-content: start;
    font-size: .875rem;
}

/* ================
 MODIFIER(S)
================= */

.share__item--facebook-custom-label {
    flex: 1 1 auto;
    max-width: 310px;

    :lang(en) & {
        max-width: 300px;
    }
}
