/* ================
 BLOCK
================= */

.message {
    --icon-size: 1.5rem;
    --gap-size: 1em;
    --color-1: var(--gray-90);
    --color-2: var(--gray-96);
    border-radius: var(--border-radius-base);
    border: 1px solid var(--color-1);
    background: var(--color-2);
    margin: var(--gap-size) 0;
    padding: var(--gap-size);
    font-size: .75em;
    display: grid;
    grid: auto / auto 1fr auto;
    gap: 0 var(--gap-size);
    align-items: center;
    grid-template-areas:
        'icon heading close'
        '. message .'
        '. button .';
}

@media (max-width: 640px) {
    .message {
        --icon-size: 1.25rem;
        --gap-size: .75em;
    }
}

/* ================
 ELEMENTS
================= */

.message__heading {
    font-size: 1rem;
    margin: 0;
    grid-area: heading;
}

.message__message {
    grid-area: message;
    margin: 0;
    line-height: 1.6;

    p:first-child {
        margin-block-start: .25em;
    }
}

.message__icon {
    width: var(--icon-size);
    aspect-ratio: 1;
    grid-area: icon;
    color: var(--color-1);
    display: none;
}

.message__close {
    --close-icon-size: 1em;
    grid-area: close;
    align-self: start;
    width: var(--target-size);
    height: var(--target-size);
    margin: calc((var(--target-size) - var(--close-icon-size)) * -.5);
    display: grid;
    place-items: center;
    cursor: pointer;

    &:focus,
    &:hover {
        color: currentColor;
    }
}

.message__close-icon {
    width: var(--close-icon-size);
    height: var(--close-icon-size);
    color: var(--gray-46);
}

.message__button {
    grid-area: button;
    place-self: start;
    margin-block-start: calc(var(--gap-size) - .5em);
    font-size: 1rem;
}

/* ================
 MODIFIER(S)
================= */

.message[data-type='notification'] {
    --color-1: var(--blue-dark);
    --color-2: var(--blue-lighter);

    .message__icon--notification {
        display: block;
    }
}

.message[data-type='warning'] {
    --color-1: var(--yellow);
    --color-2: var(--yellow-light);

    .message__icon--warning {
        display: block;
    }
}

.message[data-type='error'] {
    --color-1: var(--red-dark);
    --color-2: var(--red-light);

    .message__icon--error {
        display: block;
    }
}

.message:where([data-type='success'],[data-type='autofill']) {
    --color-1: var(--green);
    --color-2: var(--green-light);
}
.message[data-type='success'] .message__icon--success,
.message[data-type='autofill'] .message__icon--autofill {
    display: block;
}

.message--no-margin {
    margin: 0 !important;
}

.message--no-heading {
    grid-template-areas: 'icon message close' '. button .';
    align-items: start;
}

.message--no-icon {
    grid-template-areas: 'heading close' 'message .' 'button .';
    align-items: start;

    .message__icon {
        display: none;
    }
}

.message--shadow {
    box-shadow: 0 4px 24px var(--color-2);
}

.message--flash-message {
    position: fixed;
    left: 50%;
    top: calc(var(--header-height) / 4);
    transform: translateX(-50%);
    margin: 0;
    z-index: 999;
    width: calc(100% - 4rem);
    max-width: 70ch;
    font-size: 1rem;
    box-shadow: 2px 3px 6px var(--color-1);
    animation: flash-appear 6s linear 1s forwards;
    opacity: 0;
}

@keyframes flash-appear {
    0% {
        transform: translate(-50%, -200%);
    }

    5% {
        transform: translateX(-50%);
        opacity: 1;
    }

    95% {
          transform: translateX(-50%);
          opacity: 1;
      }

    100% {
        transform: translate(-50%, -200%);
        opacity: 0;
    }
}
