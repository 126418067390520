/* ================
 BLOCK
================= */

.co-submitters {
    .fieldset__heading {
        font-size: 1rem;
        font-weight: 600;
        margin-block-end: .5rem;
    }

    &:has(input:disabled) {
        .tag-input {
            opacity: .5;
        }
    }
}
