
.serp {
    --gap-size: var(--card-padding);
    --filter-size: 350px;
    --sticky-top: calc(var(--gap-size) + var(--header-height, 0px));
    --padding: max(var(--gap-size), var(--shadow-huge-blur));
    position: relative;
    background: white;
    margin: 0 auto;
    max-width: 3840px;
}

.serp-switch {
    background: lightgoldenrodyellow;
    border: 1px solid gold;
    border-inline-width: 0;
    padding-block: 1em;
    font-size: .875rem;
}

.serp-switch :is(h2, p) {
    display: inline;
}

.serp-switch__heading {
    font-size: inherit;
}

.serp__wrapper {
    display: grid;
    grid: auto 1fr auto / minmax(auto, var(--filter-size)) minmax(66%, 1fr);
    gap: 0 calc(var(--gap-size) * 2);
    padding: var(--padding);
    grid-template-areas: "filter header" "filter results" "filter footer";
    height: calc(100dvh - var(--header-height));
}

.serp__wrapper > form:first-child {
    display: contents;
}

.serp__fade,
.serp__header,
.serp__footer,
.serp__filter {
    position: sticky;
    min-height: 3rem;
    z-index: 2;
}

.serp__fade,
.serp__header,
.serp__filter {
    top: var(--sticky-top);
}

.serp__header,
.serp__footer {
    z-index: 10;
}

.serp__fade {
    width: 100%;
    background: linear-gradient(to bottom, white 90%, transparent);
    z-index: 1;
}

.serp__fade--top {
    --h: 5.5rem;
    top: var(--header-height);
    margin-bottom: calc((var(--padding) + var(--h)) * -1);
    height: calc(var(--padding) + var(--h));
}

.serp__fade--bottom {
    --h: 72px;
    transform: rotate(180deg);
    bottom: 0;
    margin-top: calc((var(--padding) + var(--h)) * -1);
    height: calc(var(--padding) + var(--h));
}

.serp__header {
    grid-area: header;
    min-height: inherit;
}

.serp__results {
    grid-area: results;
}

.serp__footer {
    grid-area: footer;
    gap: 1rem;
    bottom: var(--gap-size);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.serp__footer po-pagination {
    display: contents;
    margin-block: 0;
    margin-inline-start: auto;
}

.serp__filter {
    grid-area: filter;
    align-self: start;
    container: filter / inline-size;
    max-height: 100%;
}

.serp__filter .fieldset {
    margin-block-start: var(--gap-size);
    border-top: 1px solid var(--gray-90);
    padding-block-start: var(--gap-size);
}

.serp__filter .fieldset__heading {
    font-size: 1.25rem;
    margin: 0;
    font-weight: 600;
    line-height: 1;
}

.serp__filter .choices:not(.choices--inline) .choice:not(:first-child) {
    padding-block-start: 0 !important;
}

.serp__filter fieldset:has(.choices) + .clearable-boolean {
    padding-block-start: .75rem;
}

.serp__filter .form-grid:has(.filters__form-row) {
    padding-block-start: .75rem;
}

.serp__filter .choice {
    padding-block-end: 0 !important;
    border: none !important;
}

.serp__bulk-actions {
    display: flex;
    align-items: center;
    gap: 1ch;
    margin-inline-end: auto;
}

@media (width < 940px) {
    .serp__fade,
    .add-object-cta {
        display: none;
    }

    .serp__header,
    .serp__footer {
        position: relative;
        top: auto;
    }

    .serp__footer {
        flex-wrap: wrap;
        justify-content: center;
        overflow: visible;
    }

    .serp__bulk-actions {
        padding: var(--card-padding);
        border-radius: var(--border-radius-base);
        background: white;
        box-shadow: var(--shadow-huge-blur);
        position: absolute;
        left: 0;
        top: 0;
        transform: translateY(-1rem) translateY(-100%);
    }

    .serp__bulk-actions:has(button:disabled) {
        display: none;
    }

    .serp__footer po-pagination {
        flex: 1 1 100%;
        display: block;
    }
}

@media (width < 640px) {
    .serp__wrapper {
        display: flex;
        flex-direction: column;
        height: auto;
    }

    .serp__filter {
        position: fixed;
        z-index: 1000;
        width: calc(100dvw - ( 2* var(--card-padding)));
        height: calc(100dvh - ( 2* var(--card-padding)));
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all 150ms ease-in-out;
        overscroll-behavior: contain;
    }

    .serp__filter:not([open]) {
        transform: translate(100%, -50%);
    }

    .serp__results {
        flex: 1 0 auto;
    }

    .serp::after {
        content: '';
        position: fixed;
        inset: 0;
        background: rgb(0 0 0 / .25);
        z-index: 999;
        pointer-events: none;
        opacity: 0;
        transition: all 150ms ease-in-out;
    }

    .serp:has(.serp__filter[open])::after {
        pointer-events: all;
        opacity: 1;
        -webkit-backdrop-filter: blur(3px);
        backdrop-filter: blur(3px);
    }
}

.serp__other-partners .clearable-boolean {
    margin-block-start: .25rem;
}

.serp__other-partners .clearable-boolean:first-of-type {
    margin-block-start: 0;
}

.serp__other-partners-toggle {
    --icon-size: 24px;
    padding-inline: var(--icon-size);
    all: unset;
    display: block;
    width: 100%;
    text-align: center;
    cursor: pointer;
    color: var(--blue-light);
    text-decoration: underline;
    user-select: none;
    margin-block-start: .5rem;
}

.serp__other-partners-toggle::-webkit-details-marker {
    display: none;
}

.serp__other-partners-toggle:is(:hover, :focus) {
    color: var(--blue);
}

.serp__other-partners-toggle-icon {
    display: inline-block;
    vertical-align: middle;
    width: var(--icon-size);
    height: var(--icon-size);
    margin-right: calc(-1 * var(--icon-size));
}


[open] .serp__other-partners-toggle--show {
    display: none;
}

.serp__other-partners-toggle--hide .serp__other-partners-toggle-icon {
    transform: rotate(-180deg);
}
