:root {
    --wrapper-padding-block: 2rem;
    --wrapper-padding-inline: 1.2rem;
}


/* ================
 BLOCK
================= */

.wrapper {
    margin: 0 auto;
    width: 100%;
    max-width: calc(var(--body-width) + 4rem);
    padding: 0 2rem;
}

/* ================
 ELEMENTS
================= */

.wrapper__inner {
    /*empty for reference*/
}

/* ================
 MODIFIER(S)
================= */

.wrapper__inner--body {
    padding: var(--wrapper-padding-block) var(--wrapper-padding-inline);
    border: 1px solid var(--gray-90);
    border-top-width: 0;
    background-color: #FFFFFF;
}

.wrapper--full-width {
    --body-width: 100vw;
    padding-inline: 0;
    max-width: 3840px; /*prevent neck injuries for ultrawide users*/

    .wrapper__inner--body {
        padding: 0;
    }
}

.wrapper:where(.wrapper--small, .wrapper--extra-small),
body:has(:where(.wrapper--small, .wrapper--extra-small)) .footer.wrapper {
    max-width: 100%;
    width: calc(var(--w) + (2 * var(--card-padding)));
    padding: 0 var(--card-padding) !important;
}

.wrapper--small,
body:has(.wrapper--small) .footer.wrapper {
    --w: 640px;
}

@supports (width: 70rch) {
    .wrapper--small,
    body:has(.wrapper--small) .footer.wrapper {
        --w: 70rch;
    }
}

.wrapper--extra-small,
body:has(.wrapper--extra-small) .footer.wrapper {
    --w: 432px;
}

@supports (width: 50rch) {
    .wrapper--extra-small,
    body:has(.wrapper--extra-small) .footer.wrapper {
        --w: 50rch;
    }
}

.wrapper--transparent .wrapper__inner--body {
    border: none;
    background: none;
    padding-inline: 0;
}

.wrapper--legacy {
    --body-width: 1280px;
}
