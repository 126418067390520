/* ================
 BLOCK
================= */

.energy {
    /*empty for reference*/
}

/* ================
 ELEMENTS
================= */

.performance__expiration-date,
.index__expiration-date,
.energy__label-status {
    margin-block-end: calc(var(--form-grid-gap) / 2);
}
