/* ================
 BLOCK
================= */

.measurements {
    --min-input-size: 180px; /*derived from longest label in empty state (Oppervlakte)*/
    display: grid;
    grid: auto / repeat(auto-fit, minmax(var(--min-input-size), 1fr));
    gap: var(--form-grid-gap);

    legend {
        margin-block-end: var(--card-padding);

        .fieldset__heading--no-toggle {
            margin-block-end: 0;
        }
    }
}

/* ================
 ELEMENTS
================= */

.measurements__warning,
.measurements__error-container {
    grid-column: 1 / -1;
}

.measurements__add-row,
.measurements__total-row {
    grid-column: 1 / -1;
    max-width: calc(35ch - 0.5rem + var(--input-min-height));
}

.measurements__total-row {
    position: relative;
    padding-block-start: var(--form-grid-gap);

    &::before,
    &::after {
        position: absolute;
        top: 0;
        content: '';
    }

    &::before {
        left: 0;
        right: 0;
        border-top: 2px solid currentColor;
    }

    &::after {
        right: calc(var(--card-padding) * -1);
        transform: translate(-50%, -50%) translateY(.025em);
        content: '+';
        font-weight: bold;

    }
}

/* ================
 MODIFIER(S)
================= */

.measurements--total {
    --min-input-size: 225px;
}
