@use '../../01-mixins/mixins' as mixins;

/* ================
 BLOCK
================= */

.rent-spec {
    --card-spacing-inline-end: 2rem;
    width: 100%;

    @media screen and (min-width: 1366px) {
        @include mixins.abs-rent-spec;
    }
}

/* ================
 ELEMENTS
================= */

.rent-spec__inner {
    top: calc(var(--header-height) + var(--card-padding));
    translate: 0 var(--delta-y, 0);
    transition: translate 750ms;
}

.rent-spec__card {
    @include mixins.card;
    width: 100%;
    padding-inline-end: var(--card-spacing-inline-end);
    background: var(--gray-96);
}
.rent-spec__caption {
    font-size: .75rem;
    text-align: left;
    color: var(--gray-20);
    margin-block-start: 1em;
}

.rent-spec__heading {
    font-size: 1.125rem;
}

.rent-spec__cost {
    display: grid;
    grid: auto / 1fr auto;
    align-items: baseline;
    gap: 0 1rem;
    font-size: .875rem;
    line-height: 1.5;

    &:not(:first-child) {
        margin-block-start: .75rem;
    }
}

.rent-spec__cost-name {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    margin: 0;
}

.rent-spec__cost-cost {
    justify-self: end;
    text-align: right;
    line-height: inherit;
    margin: 0;
}

.rent-spec__error {
    margin-block: .75em;
    font-size: .75rem;
    grid-column: 1/-1;
    color: var(--red-dark);
    display: none;
}

/* ================
 MODIFIER(S)
================= */

.rent-spec--error {
    background: var(--red-light);
    box-shadow: inset 0 0 0 1px var(--red-dark);

    .rent-spec__error {
        display: block;
    }
}

.rent-spec__cost--total {
    --space: 1.25rem;
    position: relative;
    font-weight: 600;
    font-size: 1rem;
    margin-block-start: var(--space) !important;
    border-block-start: 1px solid currentColor;
    padding-block-start: var(--space);
    line-height: 1;

    &::before {
        content: '+';
        position: absolute;
        left: calc(100% + var(--card-spacing-inline-end) / 2);
        top: 0;
        transform: translate(-50%, -50%);
    }
}

.rent-spec__cost--interior {
    display: none;

    .rent-spec--has-interior & {
        display: grid;
    }
}
