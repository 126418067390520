@use '../01-mixins/mixins' as mixins;

.sr-only {
    @include mixins.sr-only
}

.sr-only-focusable:focus,
.sr-only-focusable:active {
    clip: auto !important;
    -webkit-clip-path: none !important;
    clip-path: none !important;
    height: auto !important;
    margin: auto !important;
    overflow: visible !important;
    width: auto !important;
    white-space: normal !important;
}
