.header {
    --border-color: var(--gray-73);
    --bg: white;
    position: fixed;
    top: 0;
    width: 100%;
    background: var(--bg);
    z-index: 100;
}

.header :where(ul, ol, menu) {
    margin-left: unset;
    padding-left: unset;
    list-style: none;
}

.header__nav-link:where(:hover, :active, :focus) {
    color: var(--blue-dark);
}

:where(.header__nav-heading, .header__nav-link):focus {
    text-decoration: dotted underline;
}

.header + * {
    margin-top: calc(var(--header-height, 56px) + var(--wrapper-padding-block));
}

.header + *:has(.no-header-padding, .wrapper--full-width) {
    margin-top: var(--header-height, 56px);
}

.header__logo,
.header__toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: var(--target-size);
    min-height: var(--target-size);
}

.header__toggle {
    --icon-size: 1.5rem;
    margin-right: calc((var(--target-size) - var(--icon-size)) / -2);
}

.header__toggle-inner {
    display: contents;
}

.header:not(:has(#header-toggle:checked)) .header__toggle-inner--close,
.header:has(#header-toggle:checked) .header__toggle-inner--open {
    display: none;
}

.header__toggle-icon {
    color: var(--gray-07);
    width: var(--icon-size);
    height: var(--icon-size);
}

.header__wrapper {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: var(--body-width);
    padding: .5rem var(--wrapper-padding-inline);
    display: flex;
    flex-wrap: wrap;
    gap: 0 2rem;
    justify-content: space-between;
    align-items: center;
    min-height: var(--header-height, 56px);
}

body:has(.wrapper--full-width) .header__wrapper {
    max-width: 3840px;
}

.header__nav-item:has(.header__nav-submenu) {
    display: grid;
    grid: auto / 1fr auto;
    gap: 0 1ch;
    align-items: center;
}

.header__nav-submenu-toggle {
    display: grid;
    place-content: center;
}

.header__nav-list-footer ul {
    display: contents;
    list-style: none;
}

.header__nav-link,
.header__nav-heading {
    all: unset;
    display: block;
    padding-block: calc((var(--target-size) - 1.5em) / 2);
    cursor: default;
}

.header__nav-link {
    cursor: pointer;
    display: flex;
    gap: .5ch;
    align-items: center;
}

.header input[type="checkbox"] {
    all: unset;
    display: revert;
}

@media (max-width: 1200px) {
    .header__nav {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background: var(--bg);
        transition: transform 100ms var(--transition-easing-function);
        transform: translate(100%, -1px);
        height: calc(100dvh - var(--header-height));
        overflow: auto;
    }

    .header:has(#header-toggle:checked) .header__nav {
        transform: translateY(-1px);
    }

    .header__nav-list {
        color: var(--gray-20);
        border-top: 1px solid var(--border-color);
        height: 100%;
        display: flex;
        flex-direction: column;
        font-size: 1.125rem;
        font-weight: 600;
    }

    .header__nav-item {
        border-bottom: 1px solid var(--border-color);
        flex: 0 0 auto;
        width: 100%;
    }

    .header__nav-link,
    .header__nav-heading {
        --padding-block: calc((max(var(--header-height), var(--target-size)) - 1.5em) / 2);
        padding: var(--padding-block) var(--wrapper-padding-inline);
    }

    .header__nav-submenu-toggle {
        aspect-ratio: 1;
        box-shadow: -1px 0 var(--border-color);
        place-self: stretch;
    }

    .show-submenu:checked + .chevron {
        transform: rotate(-180deg);
    }

    .header__nav-submenu .header__nav-link {
        padding-inline: calc(var(--wrapper-padding-inline) * 1.5);
    }

    .header__nav-item:not(:last-of-type) .header__nav-item:last-of-type {
        border-bottom: none;
    }

    .header__nav-list-footer {
        margin-top: auto;
        font-weight: normal;
        border-bottom: none;
        color: var(--gray-46);
        display: flex;
        gap: 1ch;
        align-items: center;
        justify-content: space-between;
        padding: 0 var(--wrapper-padding-inline);
    }

    .header__nav-list-footer .header__nav-item {
        list-style: none;
        width: auto;
        border: none;
        margin-block: 0!important;
    }

    .header__nav-list-footer .header__nav-item:has(.language-switch) {
        margin-inline-end: auto;
    }

    .header__nav-submenu {
        grid-column: 1 / -1;
        border-top: 1px solid var(--border-color);
        font-weight: normal;
        font-size: 1rem;
        display: none;
    }

    .header__nav-item:has(.show-submenu:checked) .header__nav-submenu {
        display: block;
    }
}


@media (min-width: 1201px) {
    .header {
        --sub-menu-spacing: calc((var(--header-height, 56px) - var(--target-size)) / 2);
        filter: var(--filter-shadow-huge);
    }

    .header__logo {
        margin-inline-end: .5rem;
    }

    .header__toggle {
        display: none;
    }

    .header__wrapper {
        justify-content: flex-start;
        gap: 0 1.5rem;
    }

    .header__nav,
    .header__nav-list {
        display: contents;
    }

    .header__nav-list {
        font-size: .875rem;
    }

    .header__nav-item {
        position: relative;
        margin: 0 !important;
    }

    .header__nav-list-footer .header__nav-item:has(.language-switch) {
        margin-inline: -1rem !important;
    }

    .header__nav-submenu {
        --item-inline-padding: 1em;
        position: absolute;
        top: 100%;
        left: 0;
        min-width: calc(100% + 2 * var(--item-inline-padding));
        margin-top: var(--sub-menu-spacing);
        margin-left: calc(-1 * var(--item-inline-padding));
        background: var(--bg);
        display: none;
    }

    .header__nav-submenu .header__nav-link {
        border-top: 1px solid var(--gray-96);
    }

    .header__nav-item:has(.header__nav-submenu) {
        gap: 0;
    }

    .header__nav-item .chevron {
        --size: 1em;
    }

    .header__nav-item:has(.header__nav-submenu):where(:hover, :focus-within)::before {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: var(--sub-menu-spacing);
    }

    .header__nav-item:where(:hover, :focus-within) .header__nav-submenu {
        display: block;
    }

    .header__nav-submenu .header__nav-link {
        padding-inline: var(--item-inline-padding);
    }

    .header__nav-list-footer {
        display: contents;
    }

    .header__nav-item--align-end {
        margin-inline-start: auto !important;
    }

    .header__nav-link--logout {
        color: var(--gray-46);
    }
}


