@use '../../00-variables/breakpoints' as breakpoints;
@use '../../01-mixins/mixins' as mixins;

/* ================
 BLOCK
================= */

.photo-editor {
    --max-width: 75vw;

    .card__content {
        overflow: auto;

        // allows cropper handles to escape their container
        padding: var(--card-padding);
        margin: calc(var(--card-padding) * -1);
        margin-block-end: var(--card-padding);
        width: calc(100% + var(--card-padding) * 2);
        display: flex;
        flex-wrap: wrap;

        @media (min-width: breakpoints.$lg) {
            flex-wrap: nowrap;
        }
    }
}

/* ================
 ELEMENTS
================= */

.photo-editor__image-container {
    position: relative;
    flex: 1 1 100%;
    aspect-ratio: 16/9;
    border-radius: var(--border-radius-base);
    overflow: hidden;

    .cropper-container {
        width: 100% !important;
    }

    .cropper-bg,
    .cropper-modal {
        border-radius: inherit;
    }

    .photo-editor--initializing & {
        overflow: hidden;
    }
}

.photo-editor__image {
    opacity: 0;
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: auto;
}

.photo-editor__fields {
    flex: 1 0 320px;
    max-width: 100%;
    display: grid;
    grid: auto / 1fr 1fr;
    gap: var(--card-padding);
    align-self: flex-start;
}

.photo-editor__input,
.photo-editor__button {
    grid-column: 1 / -1;
}

.photo-editor__button {
    justify-content: flex-start;

    .btn__icon--stroke {
        stroke: currentColor;
    }

    .btn__icon--mirror {
        transform: scaleX(-1);
    }
}

.photo-editor__spinner {
    @include mixins.center;
    width: 100px;
    height: auto;
    aspect-ratio: 1;
    z-index: 1;
    color: var(--gray-73);
    display: none;
}


.photo-editor__nav-button {
    --btlr: 50%;
    --btrr: 50%;
    --bblr: 50%;
    --bbrr: 50%;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 3rem;
    height: 10rem;
    max-width: calc(50% - 1rem);
    max-height: 75%;
    cursor: pointer;
    z-index: 1;

    .btn__icon {
        width: 2rem;
        aspect-ratio: 1;
    }
}

.photo-editor__nav-button-inner {
    @include mixins.center;
    background: white;
    width: 100%;
    aspect-ratio: 1;
    border-radius: var(--btlr) var(--btrr) var(--bbrr) var(--bblr);
    display: grid;
    place-content: center;
    transition: var(--transition-base);
    box-shadow: var(--shadow-small);
}

.photo-editor__nav-button--prev {
    --btlr: 0;
    --bblr: 0;

    left: 0;

    &:not(:where(:hover, :focus)) .photo-editor__nav-button-inner {
        transform: translate(-120%, -50%);
    }
}

.photo-editor__nav-button--next {
    --btrr: 0;
    --bbrr: 0;

    right: 0;

    &:not(:where(:hover, :focus)) .photo-editor__nav-button-inner {
        transform: translate(20%, -50%);
    }
}

.photo-editor__notification {
    grid-column: 1/-1;
    margin: 0;
}

/* ================
 MODIFIER(S)
================= */

.photo-editor__button--single {
    grid-column: auto / span 1;
}

.photo-editor--initializing,
.photo-editor--loading {
    user-select: none;

    .photo-editor__image-container {
        background: var(--gray-96);
        pointer-events: none;
    }

    .photo-editor__image-container > *:not(.photo-editor__spinner) {
        display: none;
    }

    .photo-editor__image-container .photo-editor__spinner {
        display: block;
    }
}

.photo-editor--initializing {
    .photo-editor__image-container,
    .photo-editor__input,
    .form-row__input-container,
    .form-row__label,
    .photo-editor__button {
        background: var(--gray-96);
        color: transparent;
        border: currentColor;
        box-shadow: none;
        pointer-events: none;
    }
}
