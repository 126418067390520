@use '../01-mixins/mixins' as mixins;

@layer card {
    /* ================
     BLOCK
    ================= */
    .card {
        @include mixins.card;
    }

    /* ================
     ELEMENTS
    ================= */
    .card__header {
        margin: calc(-1 * var(--card-padding));
        margin-bottom: var(--card-padding);
        display: grid;
        grid: auto / var(--card-close-button-size) 1fr var(--card-close-button-size);
        gap: 1rem;
        grid-template-areas: '. heading button';
        place-items: center;
        color: #000000;
        border-bottom: 1px solid currentColor;
    }

    .card__heading {
        grid-area: heading;
        margin: 0;
        font-size: 1rem;
        font-weight: bold;
    }

    .card__close {
        grid-area: button;
        display: inline-grid;
        place-content: center;
        align-self: start;
        width: var(--card-close-button-size);
        height: var(--card-close-button-size);
        cursor: pointer;

        &:hover,
        &:focus {
            outline: none;
        }

        &:focus {
            background: #000000;
            color: #FFFFFF;
        }
    }

    .card__close-icon {
        width: 1rem;
        height: 1rem;
        transition: transform var(--transition-duration) ease-out;

        .card__close:where(:hover, :focus) & {
            transform: scale(1.1);
        }
    }

    .card__content {
        max-width: calc(70ch + var(--card-padding) * 2);
        padding-inline: var(--card-padding);
        margin-inline: calc(var(--card-padding) * -1);
        display: grid;
        grid: auto / 1fr;
        gap: var(--card-padding);
        overflow-x: visible;
        overflow-y: hidden;

        &:not(:last-child) {
            margin-bottom: var(--card-padding);
        }
    }

    .card__footer {
        position: relative;

        &::before {
            content: attr(data-scroll-for-more);
            position: absolute;
            bottom: 100%;
            left: 0;
            width: 100%;
            line-height: 3;
            font-size: .875rem;
            text-align: center;
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, .66) 16.5%, rgba(255, 255, 255, 1));
            pointer-events: none;
            transition: all 150ms ease-out;
            opacity: 0;
            transform: translateY(10%);
        }
    }

    /* ================
     MODIFIER(S)
    ================= */
    .card--shadow {
        box-shadow: var(--shadow-huge);
    }

    .card--full-width-content .card__content {
        max-width: none;
    }

    .card--scroll-for-more .card__footer::before {
        opacity: 1;
        transform: none;
    }
}
