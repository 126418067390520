/* ================
 BLOCK
================= */

/*
    Please beware that this styling is to imitate the current Nomis styling.
    Therefore, some questionable choices are made.
*/

.nomis-tabs {
    position: relative;
    --underline-color: var(--blue-dark);
    font-size: .8125rem;
    font-weight: bold;
    color: #767676;
    margin-bottom: 2rem;

    &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: calc(-1.2rem + 2px);
        right: calc(-1.2rem + 2px);
        border-top: 1px solid #BBBBBB;
    }
}

/* ================
 ELEMENTS
================= */

.nomis-tabs__header {
    color: var(--text-color);
    padding-left: 5px;
    font-weight: normal;
    margin-block-end: .5lh;
}

.nomis-tabs__heading {
    font-size: 1.125rem;
    margin: 0;
    font-weight: bold;
}

.nomis-tabs__subheading {
    margin-block-start: .125lh;
}

.nomis-tabs__list {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
}

.nomis-tabs__tab:not(:first-child) {
    padding-left: 2.5px; /*don't ask*/
}

.nomis-tabs__link {
    display: inline-block;
    padding: 5px;
    padding-bottom: 8px;
    border-bottom: 3px solid transparent;

    &::part(button) {
        --btn-icon-size: 1rem;
        --btn-icon-gap: .5ch;
    }

    &:is(po-form-dialog) {
        border-bottom: none;
    }
}

.nomis-tabs__icon {
    display: inline-block;
    vertical-align: middle;
    margin-top: -.5em;
}

/* ================
 MODIFIER(S)
================= */

.nomis-tabs__link--current {
    color: var(--text-color);
    border-bottom-color: var(--underline-color);
}

.nomis-tabs__tab:not(.nomis-tabs__tab--right) + .nomis-tabs__tab--right {
    margin-left: auto;
}
