@use '../00-variables/breakpoints' as breakpoints;
@use '../01-mixins/mixins' as mixins;

/* ================
 BLOCK
================= */

.btn {
    // ensures minimal dimensions, keeping touch screens into account
    --btn-actual-size: max(var(--btn-target-size), var(--target-size));

    --btn-bg-color: var(--blue-dark);
    --btn-color: #FFFFFF;
    --btn-border-radius: 3px;
    --btn-icon-size: 1.5em;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 1ch;
    min-width: var(--btn-actual-size);
    min-height: var(--btn-actual-size);
    padding: var(--target-padding);
    padding-block: calc((var(--btn-actual-size) - 1.5em) / 2);
    background: var(--btn-bg-color);
    color: var(--btn-color);
    border-radius: var(--btn-border-radius);
    line-height: 1.5;
    font-family: inherit;
    font-weight: bold;
    user-select: none;
    vertical-align: middle;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    &:where(:focus-visible, :has(input:focus))::before{
        content: '';
        position: absolute;
        inset: -4px;
        border: 2px solid var(--btn-bg-color);
        border-radius: calc(var(--btn-border-radius) * 2);
        opacity: .5;
    }

    // for Safari

    [data-no-support*='focus-visible'] &:focus:not(:hover):not(:active)::before {
        content: '';
        position: absolute;
        inset: -4px;
        border: 2px solid var(--btn-bg-color);
        border-radius: calc(var(--btn-border-radius) * 2);
        opacity: .5;
    }

    &:hover,
    &:active {
        --btn-bg-color: var(--blue-light);
    }

    &:active {
        box-shadow: inset 0 2px .75em rgba(0, 0, 0, .1);
    }

    & > * {
        pointer-events: none;
    }
}

/* ================
 ELEMENTS
================= */

.btn__icon,
.btn__spinner {
    color: currentColor;
    display: block;
    width: var(--btn-icon-size);
    height: var(--btn-icon-size);
    aspect-ratio: 1 / 1;
    align-self: auto;
    justify-self: flex-end;
}

@media (max-width: breakpoints.$lg) {
    .btn__label {
        text-transform: capitalize;
    }

    .btn__label-expansion {
        @include mixins.sr-only;
    }
}

.btn__spinner {
    display: none;
}

/* ================
 MODIFIER(S)
================= */

.btn--secondary {
    --btn-bg-color: var(--orange);

    &:hover,
    &:active {
        --btn-bg-color: var(--orange-dark);
    }
}

.btn--tertiary {
    --btn-bg-color: var(--green);

    &:hover,
    &:active {
        --btn-bg-color: var(--green-dark);
    }
}

.btn--destructive {
    --btn-bg-color: var(--red);


    &:hover,
    &:active {
        --btn-bg-color: var(--red-dark);
    }
}

.btn--ghost {
    background: transparent;
    color: var(--btn-bg-color);
    box-shadow: inset 0 0 0 1px currentColor;

    &:active {
        box-shadow: inset 0 0 0 1px currentColor, inset 0 2px .75em rgba(0, 0, 0, .1);
    }
}

.btn--ghost-solid {
    background: white;
}

.btn--transparent,
.btn--link {
    --btn-color: inherit;
    --btn-bg-color: transparent !important;
    box-shadow: none !important;
    font-size: inherit;

    &:focus-visible {
        outline: 1px dotted currentColor;
    }
}

.btn--link {
    text-decoration: underline;
    font-weight: inherit;
    padding-inline: 0;
    --btn-color: var(--blue);

    &:hover,
    &:focus {
        --btn-color: var(--blue-light);
        text-decoration: none;
    }
}

.btn:disabled,
:disabled .btn {
    --btn-bg-color: var(--gray-96);
    --btn-color: var(--gray-46);
    pointer-events: none;

    &.btn--ghost {
        --btn-bg-color: var(--gray-46);
    }
}

.btn--small {
    --btn-target-size: 2rem;
}

.btn--pending {

    .btn__icon--right {
        display: none;
    }

    .btn__spinner {
        display: block;
    }
}

.btn--truncated-label .btn__label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.btn--align-end {
    display: flex;
    margin-inline-start: auto;
}

.btn--icon-only {
    min-width: var(--btn-target-size);
    padding-inline: 0;

    .btn__icon ~ * {
        @include mixins.sr-only;
    }
}

// legacy styling, for now...

.btn--oldskool {
    --btn-actual-size: 1.5rem;
    --btn-bg-color: var(--orange);
    --btn-border-radius: 0;
    text-shadow: 0 1px rgba(0, 0, 0, 10%);
    font-size: .75rem;
    transition: all 218ms ease;

    &:focus,
    &:hover {
        --btn-bg-color: #FF7722;
    }
}

.btn--oldskool-secondary {
    --btn-bg-color: #F5F5F5;
    box-shadow: inset 0 0 0 1px #CCCCCC;
    color: #666666;

    &:focus,
    &:hover {
        --btn-bg-color: #F8F8F8;
        box-shadow: inset 0 0 0 1px #AAAAAA;
    }
}

.btn--oldskool-close {
    --btn-actual-size: 1.25rem;
    --btn-icon-size: .625rem;
    padding: 0;
    box-shadow: none;
    transition: none;

    &:focus,
    &:hover {
        box-shadow: inset 0 0 0 1px #747474;
    }
}

.btn--inline {
    min-height: auto;
    padding: 0;
    background: transparent !important;
    box-shadow: none !important;
    color: currentColor;
    align-items: unset;
}

.btn--narrow-icon {
    --btn-icon-gap: var(--btn-padding-inline);
}

.btn--narrow-icon .btn__icon {
    width: auto;
    aspect-ratio: auto;
}

.btn--align-left {
    justify-content: flex-start;
}

.btn--align-right {
    justify-content: flex-end;
}
