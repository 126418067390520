/* ================
 BLOCK
================= */

.messages-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(119, 119, 119, .7);
    z-index: 9999;
}

/* ================
 ELEMENTS
================= */

.messages-popup__modal {
    background: white;
    border: 1px solid #DDDDDD;
    width: 550px;
    height: 430px;
    max-width: 90vw;
    max-height: 90vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.messages-popup__header {
    display: flex;
    margin-bottom: 20px;
    flex: 0 0 auto;
}

.messages-popup__heading {
    flex: 1 1 auto;
    font-size: 1.21rem;
    font-weight: bold;
    margin: 0;
}

.messages-popup__message-container {
    flex: 1 1 auto;
    overflow-y: scroll;
}

.messages-popup__footer {
    margin: 20px -16px 0;
    border-top: 1px solid #DDDDDD;
    padding: 0 16px;
    flex: 0 0 auto;
}

.messages-popup__message:not(:last-child) {
    margin-bottom: 10px;
}

.messages-popup__message-header {
    font-weight: bold;
    color: #444444;
    font-size: 14px;
    border-bottom: 1px solid #BBBBBB;
    padding-bottom: 5px;
    margin: 0;
}

.messages-popup__message-content,
.messages-popup__message-age {
    font-size: 13px;
    color: #666666;
    padding: 10px 0;
    line-height: 16px;
}

.messages-popup__message-content {
    a {
        color: #444444;

        &:where(:focus,:hover) {
            color: #111111 !important;
        }

    }
}

.messages-popup__message-age {
    font-size: 10px;
    font-style: italic;
}

.messages-popup__button {
    margin-right: -10px;
    padding: 0;
    background: #F9F9F9;
    color: #666666;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border: 1px solid transparent;

    &:hover,
    &:focus {
        border-color: #747474;
    }
}

.messages-popup__delete-icon {
    flex: 0 0 auto;
    width: 10px;
    height: 10px;
    margin-right: 1ch;

    .messages-popup__button--icon-only & {
        margin: 0;
    }
}

/* ================
 MODIFIER(S)
================= */

.messages-popup__button--footer {
    display: block;
    margin: 10px 0 0 auto;
    padding: .4em 1em;
    font-size: 12px;
    background: var(--orange);
    color: white;
    width: auto;
    height: auto;
    border: none;

    &:hover,
    &:focus {
        background: #FF7722;
    }
}
