@use '../../00-variables/breakpoints' as breakpoints;

/* ================
 BLOCK
================= */

.completometer {
    --background-track-color: var(--gray-73);
    --progress-track-color: var(--gray-46);
    position: sticky;
    bottom: 0;
    background: rgb(255, 255, 255);
    margin:calc(var(--card-padding) * -1);
    margin-top:  calc(var(--card-padding) * 2);
    padding: var(--card-padding);
    display: grid;
    grid: auto / 1fr 4rem;
    place-items: center start;
    grid-template-areas: "states progress";
    gap: var(--card-padding);
    border-radius: 0 0 var(--border-radius-base) var(--border-radius-base);
    transition: all 250ms ease-out;

    &::before {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 0;
        width: 100%;
        height: calc(var(--card-padding) * 2);
        background: linear-gradient(to top, rgb(255, 255, 255), rgba(255, 255, 255, 0));
    }

    @media screen and (max-width: breakpoints.$md) {
        width: calc(100% +  var(--card-close-button-size) + 2 * var(--card-padding));
        bottom: calc(var(--card-padding) * -1);
    }
}

/* ================
 ELEMENTS
================= */

.completometer__states {
    grid-area: states;
}

.completometer__state {
    display: grid;
    grid: auto / repeat(2, auto);
    gap: 0 1ch;
    place-items: center start;
    font-size: clamp(1rem, 2vw, 1.5rem);
    line-height: 1;

    @media screen and (max-width: breakpoints.$lg) {
        grid: auto / 1fr;
        gap: .5ch;
    }
}

.completometer__label {
    grid-column: 1 / -1;
    font-size: clamp(.875rem, 1.5vw, 1rem);

    &:not(:last-child) {
        margin-bottom: .5em;
    }
}

.completometer__target,
.completometer__trophy {
    opacity: .25;
}

.completometer__target {
    font-weight: 700;
}

.completometer__alert,
.completometer__progress,
.completometer__trophy {
    grid-area: progress;
    place-self: center;
}

.completometer__alert,
.completometer__trophy {
    transition: all 150ms;
    transition-timing-function: var(--transition-easing-bounce);
}

.completometer__alert {
    width: 45%;
    transform: translateY(-4%) scale(0);
    color: var(--yellow);
}

.completometer__trophy {
    height: 1.5rem;
    width: auto;
    transform: scale(.75);
}

.completometer__progress {
    width: 100%;
}

.completometer__progress-color {
    fill: var(--progress-track-color);
    transition: all 150ms ease-in-out;
}

.completometer__shimmer {
    opacity: 0;
    transition: all 150ms ease-in-out;
}

.completometer__shadow-caster {
    fill: #FFFFFF;
    filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.25));
}

/* ================
 MODIFIER(S)
================= */

.completometer__track--background {
    stroke: var(--background-track-color);
}

.completometer__track--progress {
    stroke-dashoffset: 200;
    transition: all 250ms;
    transition-timing-function: cubic-bezier(.17, .67, .64, 1.19);
}

.completometer__target--ready {
    opacity: 1;
}

.completometer__state--error {
    display: none;
}

.completometer--init {
    opacity: 0;
    filter: grayscale(100%);
    transform: translateY(50%);
}

.completometer--pararius-ready {
    --progress-track-color: var(--blue-dark);
}

.completometer--tiara-ready {
    --progress-track-color: var(--green-dark);
}

.completometer--trophy-ready {
    --progress-track-color: gold;

    .completometer__shimmer {
        opacity: 1;
    }

    .completometer__track--progress {
        stroke-dashoffset: 100!important;
    }

    .completometer__trophy {
        opacity: 1;
        transform: none;
    }
}

.completometer--has-errors {
    .completometer__state--state {
        display: none;
    }

    .completometer__state--error {
        display: block;
    }

    .completometer__alert {
        transform: translateY(-4%) scale(1);
    }

    .completometer__trophy {
        opacity: 0;
    }
}
