/* ================
 BLOCK
================= */

.utils {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 101;
}

/* ================
 ELEMENTS
================= */

.utils__link {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    line-height: 1.5em;
    min-width: var(--target-size);
    min-height: var(--target-size);
    padding: var(--target-padding-block);
    box-sizing: border-box;
}

.utils__link:hover,
.utils__link:focus {
    background: #FFFFFF;
}

.utils__icon {
    display: block;
    width: 1rem;
    height: auto;
}
