/* ================
 BLOCK
================= */

.fieldset-group {
    --legend-font-size: 1.25rem;
    --margin-start: 0;
    position: relative;
    margin-block-start: var(--margin-start);

    & ~ & {
        --margin-start: 1.5rem;
    }
}

/* ================
 ELEMENTS
================= */

.fieldset-group__legend {
    --target-padding-inline: 1ch;
    --distance-block: calc(var(--card-padding) - var(--target-padding-block) - .25 * var(--legend-font-size));
    --distance-inline: calc(var(--card-padding) - var(--target-padding-inline));

    position: absolute;
    top: var(--distance-block);
    left: var(--distance-inline);
    right: var(--distance-inline);
    z-index: 1;

    &.fieldset-group__legend--has-toggle {
        --distance-block: calc((3.5rem - var(--target-size)) / 2);
        --distance-inline: var(--card-padding);
    }
}

.fieldset-group__heading {
    margin: 0;
    line-height: 1;
    font-size: var(--legend-font-size);
    color: var(--blue-dark);
}

.fieldset-group__toggle {
    --btn-icon-size: 1rem;
    padding-inline: var(--target-padding-inline);
    width: 100%;

    .btn__label {
        flex: 1 0 auto;
    }

    .btn__icon {
        transition: transform var(--transition-duration);
    }

    &[aria-expanded='false'] .btn__icon {
        transform: rotate(180deg);
    }
}

.fieldset-group__content {

    :disabled &,
    &[hidden] {
        display: block;

        & > :first-child {
            padding-bottom: 0;

            * {
                display: none;
            }
        }

        & > :not(:first-child) {
            display: none;
        }
    }

    /*make room for legend/toggle*/

    & > :first-child {
        padding-top: 3.5rem;
    }

    & > :not(:first-child) {
        margin-top: .25rem;
    }
}

/* ================
 MODIFIER(S)
================= */

.fieldset-group--no-margin {
    --margin-start: 0 !important;
}
