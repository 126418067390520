/* ================
 BLOCK
================= */

.choice {
    --color-disabled: var(--gray-46);
    position: relative;
    display: grid;
    grid: auto / auto 1fr;
    grid-template-areas: 'indicator label';
    gap: .75em;
    cursor: pointer;

    &:not(:only-of-type) {
        padding: 1em 0;
        border-bottom: 1px solid var(--gray-90);
    }

    .choices > & {
        grid-column: auto / span 1;
    }
}

/* ================
 ELEMENTS
================= */

.choice__input {
    position: absolute;
    top: 50%;
    left: 0;
}

.choice__indicator {
    --accent-color: var(--blue-light);
    grid-area: indicator;
    width: 1.25em;
    aspect-ratio: 1;
    transform: translateY(.75em) translateY(-50%);
    background: #FFFFFF;
    border: 1px solid var(--gray-46);
    box-shadow: inset 0 0 0 3px #FFFFFF;

    .choice:hover &,
    .choice__input:focus ~ & {
        border-color: var(--accent-color);
        background-color: var(--gray-90);
    }

    .choice__input:focus-visible ~ & {
        box-shadow: inset 0 0 0 3px #FFFFFF, 0 0 8px rgba(14, 111, 171, .3);

        @supports (box-shadow: inset 0 0 0 3px #FFFFFF, 0 0 8px color-mix(in lch, var(--accent-color) 30%, transparent)) {
            box-shadow: inset 0 0 0 3px #FFFFFF, 0 0 8px color-mix(in lch, var(--accent-color) 30%, transparent);
        }
    }

    .choice__input:checked ~ & {
        --accent-color: var(--blue-light);
        border-color: var(--accent-color);
        background-color: var(--accent-color);
        transition: all var(--transition-duration) ease-out;
    }

    .choice__input:disabled ~ & {
        --accent-color: var(--gray-90);
        border-color: var(--accent-color);
    }

    .is-invalid ~ & {
        --accent-color: var(--red);
        border-color: var(--accent-color);
    }
}

.choice__label {
    grid-area: label;

    .choice__input:disabled ~ & {
        color: var(--color-disabled);
    }
}

/* ================
 MODIFIER(S)
================= */

.choice--toggle {
    grid-template-columns: 1fr auto;
    grid-template-areas: 'label indicator';

    .choice__label {
        margin-top: .25em;
    }
}

.choice__indicator--radio {
    border-radius: 50%;
}

.choice__input[type="checkbox"]:indeterminate ~ .choice__indicator {
    position: relative;
    background: white;
}

.choice__input[type="checkbox"]:indeterminate ~ .choice__indicator::after {
    content: '';
    position: absolute;
    inset: 40% 20%;
    background: var(--accent-color);
    opacity: .5;
}

.choice--no-label {
    gap: 0;

    .choice__indicator {
        transform: translateY(-.125em);
    }

    .choice__label {
        display: none;
    }
}
