@use '../../01-mixins/mixins' as mixins;

/* ================
 BLOCK
================= */

.tabs {
    --tab-height: calc(var(--target-size) + 1px);

    & > .fieldset {
        padding-block-start: var(--tab-height);
    }

    .tox-tinymce,
    .form-row__input-container,
    textarea {
        border-top-left-radius: 0;
    }

    .fieldset__legend,
    .rte__label,
    .form-row textarea ~ .form-row__label {
        @include mixins.sr-only;
    }

    .textarea {
        padding-top: var(--input-bottom-padding) !important;
    }

    .form-row:not(.form-row--active) {
        display: none;
    }
}

/* ================
 ELEMENTS
================= */

.tabs__nav {
    display: flex;
    font-size: 1rem;
    gap: .5ch;
    align-items: flex-end;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.tabs__tab {
    font-weight: 600;
    display: flex;
    place-items: center;
    height: var(--tab-height);
    padding-inline: calc((var(--target-size) - 1ch) / 2);
    border-radius: var(--border-radius-base) var(--border-radius-base) 0 0;
    border: 1px solid var(--gray-46);
    border-bottom-width: 0;
    background: var(--gray-96);
    cursor: pointer;
    user-select: none;
    transform: translateY(1px);
    z-index: 1;
    box-shadow: inset 0 -1px var(--gray-46);
    flex: 0 0 auto;
}

/* ================
 MODIFIER(S)
================= */

.tabs__tab--active {
    background: #FFFFFF;
    box-shadow: none;
}
