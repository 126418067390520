@use '../../00-variables/breakpoints' as breakpoints;


/* ================
 BLOCK
================= */

.tribool {
    --legend-width: 28ch;
    position: relative;
    border-bottom: 1px solid var(--gray-90);

    @media (min-width: breakpoints.$sm) {
        display: grid;
        grid: auto / 2fr 3fr 2fr;
        grid-template-areas: "yes unknown no";
        align-items: center;
    }

    @media (min-width: breakpoints.$lg) {
        padding-left: var(--legend-width);
    }
}

/* ================
 ELEMENTS
================= */

.tribool__legend {
    font-weight: 600;

    @media (min-width: breakpoints.$lg) {
        position: absolute;
        left: 0;
        top: 50%;
        width: calc(var(--legend-width) - var(--card-padding));
        transform: translateY(-50%);
    }
}

.tribool__options {
    display: contents;
}

.tribool__option {
    border-bottom: none !important;
}

/* ================
 MODIFIER(S)
================= */

.tribool--flex {
    @media (min-width: breakpoints.$sm) {
        display: flex;
        gap: var(--form-grid-gap);

        .tribool__option {
            width: calc(33.333% - .5 * var(--form-grid-gap));
            flex: 0 0 auto;
        }
    }
}

.tribool__option--yes {
    grid-area: yes;
}

.tribool__option--unknown {
    grid-area: unknown;
}

.tribool__option--no {
    grid-area: no;
}
