@use '../../00-variables/breakpoints' as breakpoints;
@use '../../01-mixins/mixins' as mixins;

/* ================
 BLOCK
================= */

.interior {
    padding-block-start: var(--card-padding);

    & > .fieldset__legend {
        @include mixins.sr-only;
    }

    .choices {
        --choice-width: 100%;

        @media screen and (min-width: breakpoints.$md) {
            --choice-width: 30%;
        }
    }
}

/* ================
 ELEMENTS
================= */

.interior__costs-upholstery-input-container,
.interior__costs-furnishing-input-container {
    max-width: 27ch;
}

/* ================
 MODIFIER(S)
================= */

.interior--optionally-furnished .interior__optionally-furnished {
    grid-column-start: 1 !important;
    grid-column-end: -1 !important;
}
