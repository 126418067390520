@use '../../00-variables/breakpoints' as breakpoints;


/* ================
 ELEMENTS
================= */

.building__construction-year-input-container {
    max-width: 19ch;

    :lang(nl) & {
        max-width: 13ch;
    }
}

@media (min-width: breakpoints.$md) {
    .building__construction-year {
        position: relative;
        margin-bottom: 1.5em !important;
    }

    .building__construction-year-hint {
        position: absolute;
        top: 100%;
        left: 0;
        white-space: nowrap;
    }
}
