@use '../../01-mixins/mixins' as mixins;

/* ================
 BLOCK
================= */

.form-modal__overlay {
    --max-width: 80ch;
    --viewport-margin: max(2rem, env(safe-area-inset-top), env(safe-area-inset-right), env(safe-area-inset-bottom), env(safe-area-inset-left));
}

/* ================
 ELEMENTS
================= */

.form-modal__content {
    overflow-x: hidden;
    overflow-y: auto;
}

.form-modal__overlay {
    @include mixins.backdrop-blur;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    z-index: 1000;
    padding: max(var(--target-size), env(safe-area-inset-top, var(--target-size))) max(var(--target-size), env(safe-area-inset-right, var(--target-size))) max(var(--target-size), env(safe-area-inset-bottom, var(--target-size))) max(var(--target-size), env(safe-area-inset-left, var(--target-size)));
}

.form-modal__dialog {
    width: calc(100vw - 2 * var(--viewport-margin));
    max-height: calc(100vh - 2 * var(--viewport-margin));
    max-width: calc(var(--max-width) + var(--card-padding) * 2);
    display: grid;
    grid: auto 1fr auto / 1fr;

    fieldset:only-child legend {
        display: none;
    }
}

.form-modal__cta-container {
    display: flex;
    align-content: flex-start;
    justify-content: flex-end;
    gap: var(--card-padding);

    .btn {
        width: auto !important;
        flex: 0 0 auto;
    }
}
