@use '../../01-mixins/mixins' as mixins;

/* ================
 BLOCK
================= */

.omni-search {
    text-transform: initial;
    font-size: 1rem;
}

/* ================
 ELEMENTS
================= */

.omni-search__open {
    background: var(--orange);
    background-size: 1rem;
    display: block;
    border: 0;
    border-radius: 0;
    width: var(--target-size);
    height: var(--target-size);

    &::after {
        content: '';
        width: 1rem;
        height: 1rem;
        background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbD0iI2Y2MCIgZD0ibTUwOS40NTU5Niw0MTYuOTM1M2wtMTQ1LjQxNTEsLTE0NC4wOTc1YzYwLjQyMjY0LC0xMjQuMTc0MzkgLTMwLjkxNjA4LC0yNzAuODM3ODIgLTE3MS45Mjg1NiwtMjcwLjgzNzgyYy0xMDUuMTcyMjcsMCAtMTkwLjczNzI4LDg0Ljc4OTczIC0xOTAuNzM3MjgsMTg5LjAxMDMxYzAsMTM3LjQ5MjU1IDE0My42OTI1MywyMjguMTMyODQgMjY4LjYzNDc3LDE3Mi41MjE5NGwxNDYuNjY5MzcsMTQ1LjMzNjc2YzQxLjcxNTcsMy40NDk1NSA5Ni4zNzcwNCwtNDkuMTYwOTggOTIuNzc2NzksLTkxLjkzMzY5em0tMzE3LjM0MzY2LC05OS43MzIzM2MtNzAuMjEzNzgsMCAtMTI3LjMzOTA5LC01Ni42MDg5NSAtMTI3LjMzOTA5LC0xMjYuMTkyNjljMCwtNjkuNTgwMDYgNTcuMTI1MzEsLTEyNi4xODkwMiAxMjcuMzM5MDksLTEyNi4xODkwMmM3MC4yMTg3OCwwIDEyNy4zNDY1Niw1Ni42MDg5NiAxMjcuMzQ2NTYsMTI2LjE4OTAyYzAsNjkuNTgzNzQgLTU3LjEyNzc4LDEyNi4xOTI2OSAtMTI3LjM0NjU2LDEyNi4xOTI2OXoiLz4KPC9zdmc+Cg==') no-repeat center center;
        background-size: contain;
        filter: grayscale(100%) brightness(200%);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &:hover,
    &:focus {
        background-color: var(--orange-dark);
    }

    &:focus-visible {
        background: #FFFFFF;
        color: var(--orange);
        box-shadow: inset 0 0 0 .25ch currentColor;

        &::after {
            filter: none;
        }
    }
}

.omni-search__close {
    --btn-icon-size: 1ex;
    background: none !important;
    font-weight: normal;
    padding-right: 0;
    position: absolute;
    bottom: 100%;
    right: 2rem;
    transition: all 200ms ease;
    color: var(--gray-73);


    &:hover,
    &:focus {
        color: var(--text-color);
    }
}

.omni-search__overlay {
    @include mixins.backdrop-blur(5px,  rgba(255, 255, 255, .85),  rgba(255, 255, 255, .97));
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
}

.omni-search__content {
    position: relative;
    margin-top: 13vh;
}

.omni-search__heading {
    font-size: 1.5rem;
    color: var(--gray-46);
    margin: 0;

    span {
        color: var(--blue-dark);
    }
}

.omni-search__subheading,
.omni-search__label {
    font-style: italic;
    color: var(--gray-73);
    display: inline-block;
    margin: 0;
}

.omni-search__input {
    display: block;
    width: 100%;
    line-height: 1;
    font-size: 3.75rem;
    font-weight: bold;
    font-family: inherit;
    letter-spacing: -.0033em;
    padding: 0;
    margin: 0 0 1em;
    border: 0;
    background: none;
    text-transform: uppercase;
    color: #111111;
}

.omni-search__results {
    display: grid;
    grid: auto / repeat(auto-fill, minmax(320px, 1fr));
    gap: 1rem;

    &::after {
        text-align: center;
        grid-column: 1 / -1;
        font-size: 1.25rem;
        font-style: italic;
        color: var(--gray-90);
    }

    &:not(.omni-search__results--loading):empty::after {
        content: attr(data-no-results);
    }
}

.omni-search__category-heading {
    color: var(--gray-46);
    font-size: .78rem;
    margin: 0;
    border-bottom: 2px solid var(--gray-90);
    padding: calc((1.875rem - 1.25em) / 2) 0;
}

.omni-search__result {
    position: relative;
    padding: 8px;
    display: grid;
    grid: auto / auto 1fr;
    grid-template-areas:
        'image heading'
        'image description';
    align-items: start;
    gap: 6px;
    font-size: .8125rem;
    transition: all 200ms ease;
    box-shadow: 0 1px #E1E1E1;

    &:not([data-color]) {
        padding-left: 0;
    }

    &[data-color]::before {
        content: '';
        position: absolute;
        left: 0;
        width: 2px;
        top: 8px;
        bottom: 8px;
        background: var(--color);
    }

    &[data-color='orange'] {
        --color: #FFA500;
    }

    &[data-color='green'] {
        --color: #008000;
    }

    .omni-search__results--loading & {
        pointer-events: none;
        opacity: .5;
        filter: grayscale(80%) blur(2px);
    }
}

.omni-search__result-image {
    background: var(--gray-90);
    object-fit: cover;
    grid-area: image;
    width: 54px;
    height: 54px;
    aspect-ratio: 1/1;
}

.omni-search__result-heading {
    grid-area: heading;
    font-weight: 400;
    font-size: 1em;
    margin: 4px 0 0;
}

.omni-search__result-link {
    text-decoration: underline;
}

/* ================
 MODIFIER(S)
================= */

.omni-search__results--loading::after {
    content: attr(data-loading);
}

.omni-search__results--error::after {
    color: var(--red-dark);
    font-style: normal;
    content: attr(data-error);
}

.omni-search__result--selected {
    background: #FFFFFF;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .2), var(--shadow-huge);
    transform: scale(1.05);
}
