/* ================
 INPUTS
================= */

:root {
    --input-min-height: 3.5rem;
    --input-line-height: 1.5rem;
    --input-border-width: 1px;
    --input-border-color: var(--gray-46);
    --input-focus-color: var(--blue);
    --input-top-padding: calc(var(--input-min-height) - 2 * var(--input-border-width) - var(--input-line-height) - var(--input-bottom-padding));
    --input-right-padding: calc(2 * var(--input-icon-spacing) + var(--input-status-icon-size));
    --input-bottom-padding: .5rem;
    --input-left-padding: 1rem;
    --input-icon-spacing: 1rem;
    --input-status-icon-size: 1.5rem;
    --input-padding: var(--input-top-padding) var(--input-right-padding) var(--input-bottom-padding) var(--input-left-padding);
}
