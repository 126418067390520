/* ================
 TOUCH TARGETS
================= */

:root {
    --target-size: 44px;
    --target-padding-block: calc((var(--target-size) - 1.5em) / 2);
    --target-padding-inline: 1em;
    --target-padding: var(--target-padding-block) var(--target-padding-inline);
    --target-size-minimum: 44px;
}

@media (any-pointer: fine) {
    :root {
        --target-size: 32px;
        --target-size-minimum: 24px;
    }
}
