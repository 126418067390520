/* ================
 BLOCK
================= */

.descriptions {

}

/* ================
 ELEMENTS
================= */

.descriptions__grid {
    grid-row-gap: 0;
}

.descriptions__fieldset[hidden] {
    display: grid;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 1px;
    opacity: 0;
    pointer-events: none;
}

[data-descriptions-target="textarea"] {
    min-height: 440px;
}
