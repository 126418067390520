/* ================
 BLOCK
================= */

.files-messages {
    margin: 0;
}

/* ================
 ELEMENTS
================= */

.files-messages__heading {
    font-size: 1rem;

    &:not(:first-child) {
        margin-block-start: calc(1rem - 0.125em);
    }
}

.files-messages__filename {
    font-size: .875rem;
    font-weight: 600;
}

.files-messages__file-list,
.files-messages__message-list {
    margin: 0;
}

.files-messages__file-list {
    list-style: none;
}

.files-messages__message-list {
    font-size: .75rem;
    padding-inline-start: 1ch;
}

.files-messages__file {
    position: relative;
    z-index: 1;
    padding: .75rem 0;

    &:nth-child(odd):before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        background: var(--gray-96);
        z-index: -1;
    }
}
