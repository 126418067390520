@use '../../01-mixins/mixins' as mixins;

/* ================
 BLOCK
================= */

.thumbnail {
    padding: .5rem;
    perspective: 100px;
    order: -1;
}

/* ================
 ELEMENTS
================= */

.thumbnail__inner {
    position: relative;
    background: #FFFFFF;
    height: var(--thumbnail-height);
    aspect-ratio: 1.5;
    min-width: calc(2 * var(--target-size) + 1rem); // assures room for buttons
    border-radius: var(--border-radius-base);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 150ms ease-in-out;
    filter: drop-shadow(2px 3px 5px rgba(0, 0, 0, .25));
    user-select: none;
    overflow: hidden;
    transform-origin: right center;

    &:focus,
    &:focus-within,
    &:hover {
        filter: drop-shadow(3px 4px 7px rgba(0, 0, 0, .125));
    }

    [data-state] > & {
        pointer-events: none;
    }
}

.thumbnail__image {
    pointer-events: none;
    position: absolute;
    inset: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.thumbnail__file-icon {
    position: relative;
    aspect-ratio: 1/1.333;
    width: 2.5rem;
    transform: translateY(-10%);

    &::after {
        content: '.' attr(data-extension);
        position: absolute;
        top: 50%;
        left: 4px;
        right: 4px;
        text-align: center;
        font-weight: 600;
        font-size: .75rem;
    }
}

.thumbnail__icon {
    @include mixins.center;
    width: 100%;
    height: auto;
}

.thumbnail__filename {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 0 0 var(--border-radius-base) var(--border-radius-base);
    font-size: .75rem;
    padding: 1em;
    padding-block-end: 1ch;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: auto;
    text-align: center;
    background: linear-gradient(to top, #FFFFFF, rgba(255, 255, 255, .75) 33%, rgba(255, 255, 255, 0));
    transition: all 150ms ease-in-out;
    resize: none; // because Safari
}

.thumbnail__click-target {
    @include mixins.sr-only;
}

.thumbnail__action {
    --visual-target-size: 1.5rem;
    --foreground-color: transparent;
    --background-color: white;

    position: absolute;
    top: 0;
    width: var(--target-size);
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 100ms;
    z-index: 1;
    color: var(--foreground-color);

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: var(--visual-target-size);
        aspect-ratio: 1;
        border-radius: 50%;
        z-index: -1;
        background: var(--background-color);
    }

    @media (hover: hover) and (pointer: fine) {
        .thumbnail:not(:where(:hover, :focus, :focus-within)) input:not(:checked) + & {
            opacity: 0;
            transform: scale(.8);
        }
    }
}

.thumbnail__action--main {
    --foreground-color: var(--yellow);
    left: 0;

    .thumbnail__action-icon {
        stroke: var(--foreground-color);
        fill: transparent;
    }

    &:hover,
    &:focus {
        .thumbnail__action-icon {
            fill: var(--yellow-transparent);
        }
    }

    :checked + & {
        .thumbnail__action-icon {
            fill: var(--foreground-color);
        }
    }
}

.thumbnail__action--delete {
    --foreground-color: var(--red-dark);
    right: 0;
    z-index: 1;

    :checked + & {
        color: var(--background-color);

        &::before {
            background: var(--foreground-color);
        }
    }

    &:hover,
    &:focus {
        --foreground-color: var(--red);
    }
}

.files__thumbnails:has([data-files-target="delete"]:checked) .thumbnail__action--delete {
    opacity: 1 !important;
    transform: none !important;
}

.thumbnail__action-icon {
    width: 1rem;
    height: 1rem;
    fill: currentColor;
    pointer-events: none;
}

.thumbnail__state {
    position: absolute;
    inset: 0;
    background: rgb(0 0 0 / 50%);
    z-index: 10;
    transition: all 150ms ease-out;
    opacity: 0;
    pointer-events: none;

    .thumbnail:where([data-state="queued"], [data-state="uploading"], [data-state="processing"], [data-state="error"]) & {
        opacity: 1;
        pointer-events: all;
    }
}

.thumbnail__state-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(.8);
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: white;
    transition: all 100ms 50ms ease-in;

    .thumbnail[data-state]:not([data-state=""]) & {
        transform: translate(-50%, -50%);
    }
}

.thumbnail__clock,
.thumbnail__progress {
    display: none;
}

.thumbnail__progress {
    stroke-dashoffset: var(--progress, 100);
    transition: all 100ms linear;
    transform-origin: 25px 25px;
}

/* ================
 MODIFIER(S)
================= */

.thumbnail[data-state="queued"] .thumbnail__clock,
.thumbnail:where([data-state="uploading"], [data-state="processing"]) .thumbnail__progress {
    display: block;
}

.thumbnail[data-state="processing"] .thumbnail__progress {
    animation: rotate-state 1s linear infinite;
}

@keyframes rotate-state {
    from {
        transform: rotate(0turn);
    }

    to {
        transform: rotate(1turn);
    }
}

.thumbnail--dragover .thumbnail__inner {
    box-shadow: 1px 2px 3px rgba(0, 0, 0, .35);
    transform: rotateY(-5deg) translateX(.25rem);
}

.thumbnail--upload-error {
    order: 0;

    .thumbnail__inner {
        transform: rotate(var(--rotation));
        opacity: .5;

        &::after {
            background: rgba(255, 0, 0, 0.15);
        }
    }
}

.thumbnail__inner--has-image {
    .thumbnail__filename {
        display: none;
    }
}

.thumbnail--selected .thumbnail__inner {
    transform-origin: center center;
    transform: scale(.75);
    opacity: .5;

    .thumbnail__main,
    .thumbnail__delete {
        display: none;
    }
}

.archived .thumbnail__action {
    display: none;
}

