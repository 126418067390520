/* Generate a bunch of helper classes */

$properties: margin, padding;
$sides: top, right, bottom, left, inline, block, inline-start, inline-end, block-start, block-end;
$sizes: ("none": 0, "card": var(--card-padding), "card-half": calc(var(--card-padding) / 2));

@each $prop in $properties {
    @each $name, $val in $sizes {
        .#{$prop}-#{$name} {
            #{$prop}: #{$val} !important;
        }
        @each $side in $sides {
            .#{$prop}-#{$side}-#{$name} {
                #{$prop}-#{$side}: #{$val} !important;
            }
        }
    }
}
