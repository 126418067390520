/* ================
 BLOCK
================= */

.footer {
    /*empty for reference*/
}

/* ================
 ELEMENTS
================= */

.footer__content {
    padding: .5rem 0;
    text-align: right;
    font-size: .7rem;
    color: var(--gray-46);

    ul {
        display: inline-flex;
        flex-wrap: wrap;
        list-style: none;
        gap: 1ch;

        li {
            white-space: nowrap;
            list-style-type: none;

            &:not(:first-child) {
                border-left: 1px solid var(--gray-73);
                padding-left: 1ch;
            }
        }
    }

    .selectable {
        user-select: all;
        -webkit-user-select: all;
    }
}
