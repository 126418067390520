/* ================
 BLOCK
================= */

.user-dropdown {
    --image-size: 120px;
    padding: 1rem;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(3, var(--image-size));
    grid-template-areas:
        'image client client'
        'image credits credits'
        'settings settings logout';
    text-transform: none;
}

/* ================
 ELEMENTS
================= */

.user-dropdown__image-container {
    position: relative;
    width: var(--image-size);
    height: var(--image-size);
    overflow: hidden;
    grid-area: image;
}

.user-dropdown__image {
    background: var(--gray-90) url('../../../images/user-icon.jpg') center / cover no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    color: transparent;
}

.user-dropdown__image-edit {
    position: absolute;
    bottom: 0;
    right: 0;
    filter: drop-shadow(1px 2px 5px rgba(0, 0, 0, .75));
    display: none;
}

.user-dropdown__image-container:hover .user-dropdown__image-edit,
.user-dropdown__image-container:focus-within .user-dropdown__image-edit {
    display: block;
}

.user-dropdown__name-container {
    grid-area: client;
}

.user-dropdown__user-name {
    margin-top: 0;
}

.user-dropdown__company-name {
    color: var(--gray-90);
}

.user-dropdown__credits {
    grid-area: credits;
    align-self: end;
    justify-items: start;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-areas:
        'heading amount'
        'cta amount';
}

.user-dropdown__credits-heading {
    margin: 0;
    font-size: 1em;
    grid-area: heading;
}

.user-dropdown__credits-amount {
    grid-area: amount;
    font-size: 2em;
    font-weight: bold;
    align-self: center;
    text-decoration: none;
}

.user-dropdown__credits-cta {
    grid-area: cta;
    text-decoration: none;
    font-size: .875em;
    color: var(--gray-73);
}

.user-dropdown__credits-cta:hover,
.user-dropdown__credits-cta:focus {
    text-decoration: underline;
}

.user-dropdown__credits--low-funds .user-dropdown__credits-cta,
.user-dropdown__credits--low-funds .user-dropdown__credits-amount {
    color: var(--red-dark);
}

.user-dropdown__settings {
    grid-area: settings;
    font-size: .875em;
}

.user-dropdown__settings-link {
    color: var(--gray-46);
    text-decoration: none;
}

.user-dropdown__settings-link:hover,
.user-dropdown__settings-link:focus {
    text-decoration: underline;
    color: var(--gray-07);
}

.user-dropdown__logout {
    grid-area: logout;
    align-self: center;
    justify-self: end;
}
