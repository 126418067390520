.chevron {
    --size: 1.5em;
    display: block;
    width: var(--size);
    height: auto;
    aspect-ratio: 1;
    transition: transform 150ms linear;
}

.chevron--narrow {
    aspect-ratio: 1273/778;
}

.chevron--narrow use {
    transform-origin: 50% 50%;
    transform: scale(3);
}

.chevron--up {
    transform: rotate(180deg);
}

.chevron--left {
    transform: rotate(90deg);
}

.chevron--right {
    transform: rotate(-90deg);
}
