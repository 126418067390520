@use '../../00-variables/breakpoints' as breakpoints;
/* ================
 BLOCK
================= */

.choices {
    display: var(--choices-display, grid);
    grid: auto / repeat(auto-fill, minmax(var(--choice-width, 25ch), 1fr));
    gap: var(--choices-gap, 1rem);

    @media (max-width: breakpoints.$xs) {
        --choice-width: 100% !important;
        row-gap: 0;
    }
}

/* ================
 MODIFIER(S)
================= */

.choices--narrow {
    --choice-width: 17ch;
}

.choices--flex {
    display: flex;
    flex-wrap: wrap;

    .choice {
        flex: 1 0 auto;
    }
}

.choices--inline {
    .form-grid {
        display: flex;
        padding: var(--card-padding) 0 0;
        gap: var(--card-padding);
    }

    .choice {
        display: inline-grid;
        padding: 0;
        border-bottom: none;
    }
}
