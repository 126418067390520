@use '../../00-variables/breakpoints' as breakpoints;

/* ================
 BLOCK
================= */

.form-row {
    --warning-color: var(--yellow);
    --error-color: var(--red);
    --label-color: var(--gray-46);
    --chevron-size: 1.5rem;
}

/* ================
 ELEMENTS
================= */

.form-row__input-container {
    position: relative;
    min-height: var(--input-min-height);
    border: var(--input-border-width) solid var(--input-border-color);
    border-radius: var(--border-radius-base);
    box-shadow: var(--shadow-small);
    background: #FFFFFF;
    display: grid;
    grid: auto / auto 1fr auto;
    grid-template-areas: 'unit-left label-input-icon unit-right';

    &:focus-within {
        border-color: var(--input-focus-color);
        box-shadow: 0 0 3px var(--input-focus-color);
    }

    &.is-invalid {
        --input-focus-color: var(--error-color);
    }

    &:not(.is-invalid):has([data-autofill]:not(:disabled)) {
        --input-border-color: var(--green);
        --input-focus-color: var(--green);
    }

    &:has([data-datalist-open]) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.form-row__input,
.form-row__values {
    grid-area: label-input-icon;
    padding: var(--input-padding);
    line-height: var(--input-line-height);
    width: 100%;
    -moz-appearance: textfield; // remove spinners in Firefox
    border-radius: calc(var(--border-radius-base) - 1px);

    &:where(textarea) {
        resize: vertical;
    }

    &:not(textarea) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &:disabled {
        color: var(--gray-46);
        background: var(--gray-96);
    }

    &::-webkit-calendar-picker-indicator {
        position: absolute;
        width: auto;
        height: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        cursor: pointer;
        opacity: 0;
    }

    [autocomplete="off"] &::-webkit-contacts-auto-fill-button,
    [autocomplete="off"] &::-webkit-credentials-auto-fill-button {
        visibility: hidden;
        display: none !important;
        pointer-events: none;
        height: 0;
        width: 0;
        margin: 0;
    }
}

.form-row__values {
    padding-inline-end: var(--input-left-padding);
}

.form-row__label {
    grid-area: label-input-icon;
    display: block;
    position: absolute;
    top: 0;
    left: 1rem;
    transform: translateY(calc(var(--input-min-height) / 2)) translateY(-50%);
    transform-origin: left top;
    transition: all var(--transition-duration) ease-out;
    color: var(--label-color);
    line-height: 1.33;
    pointer-events: none; // transparent for clicks

    textarea ~ & {
        --shadow-distance: 2px;
        --shadow-blur: 3px;
        --shadow-color: white;
        text-shadow:
            calc(-1 * var(--shadow-distance)) calc(-1 * var(--shadow-distance)) var(--shadow-blur) var(--shadow-color),
            0 calc(-1 * var(--shadow-distance)) var(--shadow-blur) var(--shadow-color),
            var(--shadow-distance) calc(-1 * var(--shadow-distance)) var(--shadow-blur) var(--shadow-color),
            var(--shadow-distance) 0 var(--shadow-blur) var(--shadow-color),
            var(--shadow-distance) var(--shadow-distance) var(--shadow-blur) var(--shadow-color),
            0 var(--shadow-distance) var(--shadow-blur) var(--shadow-color),
            calc(-1 * var(--shadow-distance)) var(--shadow-distance) var(--shadow-blur) var(--shadow-color),
            calc(-1 * var(--shadow-distance)) 0 var(--shadow-blur) var(--shadow-color);
    }

    // add asterisk for required fields

    :required ~ &::after {
        content: '*';
    }

    // move label out of the way on focussed / filled field

    .form-row__input:not(.form-row__input--empty):where(:focus, :not(:placeholder-shown)) ~ &,
    .form-row__values:not(:empty) ~ & {
        transform: translateY(.5rem) scale(.75);
    }

    // error state

    .is-invalid ~ &,
    .has-errors .is-invalid > & {
        color: var(--error-color);
    }

    // disabled

    :disabled ~ & {
        color: var(--gray-73);
    }
}

.form-row__status-icon,
.form-row__chevron {
    grid-area: label-input-icon;
    position: absolute;
    top: 50%;
    right: var(--input-icon-spacing);
    transform: translateY(-50%);
    height: auto;
    pointer-events: none;

    textarea + .form-row__label ~ & {
        top: var(--input-icon-spacing);
        transform: translate(1px, -1.5px); // because pixel rounding
    }
}

.form-row__chevron {
    width: var(--chevron-size);
    color: var(--blue-light);

    .form-row__input[data-autofill] ~ & {
        color: var(--green);
    }

    .form-row__input:disabled ~ & {
        color: var(--gray-46);
    }
}

.form-row__status-icon {
    width: var(--input-status-icon-size);

    .form-row__chevron ~ & {
        right: calc(var(--chevron-size) + 2 * var(--input-icon-spacing));
    }

    use {
        display: none;
    }
}

.form-row__unit,
.form-row__toggle {
    min-width: var(--input-min-height);
    padding-inline: 1ch;
    display: grid;
    place-content: center;
    font-weight: bold;
    line-height: 1;
}

.form-row__toggle {
    grid-area: unit-right;
    border: none !important;
    cursor: pointer;
    color: var(--blue-light);

    &:hover,
    &:focus {
        color: var(--blue);
    }

    &::after {
        content: '';
        position: absolute;
        inset: 0;
    }
}

.form-row__hint,
.form-row__error {
    font-size: .75rem;
    margin-top: .5em;
}

.form-row__hint {
    color: var(--gray-46);

    .form-row__error:not(:empty) ~ & {
        display: none;
    }
}

.form-row__error {
    $space: 2ch;
    padding-inline-start: $space;

    li:only-child {
        margin-inline-start: -$space;
        width: calc(100% + #{$space});
    }

    li:not(:only-child) {
        list-style-type: disc;
    }
}


/* ================
 MODIFIER(S)
================= */

.form-row__input-container--select {
    grid-template-columns: auto;
    grid-template-areas: 'label-input-icon';
}

.form-row__input--select {
    --input-right-padding: calc(3 * var(--input-icon-spacing) + var(--input-status-icon-size) + var(--chevron-size));
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    -moz-appearance: none; // remove dropdown chevron
}

.form-row__input--empty {
    opacity: 0;
}

// remove arrows for number input

.form-row__input[type='number']::-webkit-inner-spin-button,
.form-row__input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

// force same height for date inputs

.form-row__input::-webkit-datetime-edit,
.form-row__input::-webkit-datetime-edit-fields-wrapper,
.form-row__input::-webkit-datetime-edit-day-field,
.form-row__input::-webkit-datetime-edit-month-field,
.form-row__input::-webkit-datetime-edit-year-field {
    padding-block: 0!important;
}

// hides confusing placeholder in Safari
.safari .form-row__input[type="date"].is-empty::-webkit-datetime-edit {
    display: none !important;
}

.form-row__status-icon .form-row__status-icon--valid {
    color: var(--green);

    .is-valid > .form-row__input:not(:where(.form-row__input--warning, .form-row__input--loading, :disabled, .is-empty)) ~ & {
        display: block;
    }
}

.form-row__status-icon .form-row__status-icon--invalid {
    color: var(--error-color);

    .is-invalid > .form-row__input:not(:where(.form-row__input--loading, :disabled)) ~ & {
        display: block;
    }
}

.form-row__status-icon .form-row__status-icon--warning {
    color: var(--warning-color);

    .form-row__input-container:not(.is-invalid) > .form-row__input--warning:not(:where(:placeholder-shown, .form-row__input--loading)) ~ & {
        display: block;
    }
}

.form-row__status-icon .form-row__status-icon--autofill {
    color: var(--green);

    .is-valid > .form-row__input[data-autofill]:not(:where(.form-row__input--warning, .form-row__input--loading, :disabled)) ~ & {
        display: block;
    }
}

.form-row__status-icon--loading {
    display: none;
    color: var(--gray-46);
    animation: loading-spinner 500ms linear infinite;

    .form-row__input--loading ~ & {
        display: block;
    }
}

@keyframes loading-spinner {

    from {
        stroke-dashoffset: 0;
    }

    to {
        stroke-dashoffset: -100;
    }
}

.form-row__unit--left {
    grid-area: unit-left;
    border-right: 1px solid var(--input-border-color);
}

.form-row__unit--right {
    grid-area: unit-right;
    border-left: 1px solid var(--input-border-color);
}

.form-row__unit--currency {
    padding-top: .1em;
}

.form-row__hide-errors {
    .form-row__error {
        display: none;
    }
}

.form-row--dangling {
    position: relative;

    .form-row__error,
    .form-row__hint {
        position: absolute;
        top: 100%;
    }
}

@each $name, $size in breakpoints.$breakpoints {
    @media screen and (max-width: #{$size}) {
        .form-row--dangling#{"\\@"}until-#{$name} {
            position: relative;
            .form-row__error,
            .form-row__hint {
                position: absolute;
                top: 100%;
            }
        }
    }

    @media screen and (min-width: #{$size - 1}) {
        .form-row--dangling#{"\\@"}from-#{$name} {
            position: relative;
            .form-row__error,
            .form-row__hint {
                position: absolute;
                top: 100%;
            }
        }
    }
}
