@use '../../00-variables/breakpoints' as breakpoints;

/* ================
 BLOCK
================= */

.textarea {
    border: var(--input-border-width) solid var(--input-border-color);
    border-radius: var(--border-radius-base);
    box-shadow: var(--shadow-small);
    resize: vertical;
    padding: 1ch;

    &:focus {
        border-color: var(--input-focus-color);
        box-shadow: 0 0 3px var(--input-focus-color);
    }
}

@media screen and (min-width: breakpoints.$lg) {
    .textarea--fill-height {
        height: calc(90vh - var(--header-height) - 200px)
    }
}
