/* ================
 BLOCK
================= */

.description-nav {
    margin-block: var(--form-grid-gap) !important;

    li {
        display: contents;
    }
}

/* ================
 ELEMENTS
================= */

.description-nav__list {
    background: var(--gray-96);
    border-radius: var(--border-radius-base);
    padding: 1ch;
}

.description-nav__list {
    gap: 1ch;
    display: inline-flex;
    flex-wrap: wrap;
    align-items: flex-start;
    list-style: none;
}

.description-nav__list-item {
    position: relative;
    flex: 0 0 auto;
    max-width: 100%;

    &:not(:last-of-type) li:last-child button {
        margin-inline-end: calc(1ch + 1px);

        &::after {
            position: absolute;
            content: '';
            top: 0;
            left: calc(100% + 1ch);
            height: 100%;
            width: 1px;
            background: var(--gray-46);
        }
    }
}

.description-nav__type {
    display: contents;
}

.description-nav__button {
    --btn-bg-color: var(--gray-73);
}

/* ================
 MODIFIER(S)
================= */

.description-nav__button--active {
    --btn-bg-color: var(--blue);
}
