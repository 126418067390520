/* ================
 BLOCK
================= */

.media-status {
    --bg: var(--green-light);
    --txt: var(--green-dark);

    display: inline-block;
    vertical-align: middle;
    width: auto;
    font-size: .875rem;
    font-weight: 500;
    line-height: 1.5;
    padding: 0 .75ch;
    background: var(--bg);
    color: var(--txt);
    border-radius: var(--border-radius-small);
    border: 1px solid rgba(0, 0, 0, .02);
}

/* ================
 MODIFIER(S)
================= */

.media-status--processing {
    --bg: var(--yellow-transparent);
    --txt: var(--yellow-dark);
}

.media-status--failed {
    --bg: var(--red-light);
    --txt: var(--red-dark);
}
