/* ================
 BLOCK
================= */

.summary {
    --icon-size: 1.5rem;
    content-visibility: auto;
    contain-intrinsic-size: 1px 356px;
}

/* ================
 ELEMENTS
================= */

.summary__header {
    display: grid;
    grid: auto / minmax(0, 1fr) auto; /* https://css-tricks.com/preventing-a-grid-blowout/ */
    gap: var(--card-padding);
    grid-template-areas: "address button";
    align-items: center;
    margin: 0 calc(-1 * var(--card-padding));
}

.summary__header-address {
    padding-inline-start: var(--card-padding);
}

.summary__chapter-heading {
    font-size: 1.125rem;
    margin: 0;
    margin-block: .99em .495em;
}


.summary__chapter-heading-link {
    font-size: 1rem;
    font-weight: normal;
    display: grid;
    grid: auto / var(--icon-size) 1fr var(--icon-size);
    grid-template-areas: "icon-left text icon-right";
    gap: 1ch;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}

.summary__chapter-heading-text {
    grid-area: text;
    align-self: center;
}

.summary__status-icon {
    width: var(--icon-size);
    aspect-ratio: 1;
    grid-area: icon-right;
    display: none;
}

.summary__subchapters {
    list-style-type: disc;
    font-size: .75rem;
    margin: 0;
    margin-inline-start: calc(var(--icon-size) + 3.333ch); /*icon size, heading gap + 2ch for list disc*/
    font-weight: 300;

    li:not(:last-child) {
        margin-block-end: .375em;
    }
}

.summary__subchapter-link {
    --status-color: transparent;

    position: relative;
    display: block;
    width: 100%;
    padding-right: var(--icon-size);

    &:hover,
    &:focus {
        text-decoration: underline;
    }

    &::after {
        content: '';
        width: 1ex;
        aspect-ratio: 1;
        border-radius: 50%;
        background: var(--status-color);
        position: absolute;
        right: calc((var(--icon-size) / 2) - .5ex);
        top: calc(.5625rem - .5ex);
    }
}

/* ================
 MODIFIER(S)
================= */

.summary__status-icon--placeholder,
.summary__status-icon--valid {
    grid-area: icon-left;
}

.summary__status-icon--placeholder {
    border-radius: 50%;
    background: var(--gray-90);
}

.summary__status-icon--valid,
.summary__status-icon--autofill {
    color: var(--green);
}

.summary__status-icon--warning {
    color: var(--yellow);
}

.summary__status-icon--error {
    color: var(--red);
}

.summary__chapter-heading--is-valid .summary__status-icon--valid,
.summary__chapter-heading--has-autofill .summary__status-icon--autofill,
.summary__chapter-heading--has-errors .summary__status-icon--error,
.summary__chapter-heading--has-warnings:not(.summary__chapter-heading--has-errors) .summary__status-icon--warning,
.summary__chapter-heading--has-autofill .summary__status-icon--autofill,
.summary__chapter-heading:not(.summary__chapter-heading--is-valid) .summary__status-icon--placeholder {
    display: block;
}

.summary__chapter-heading--disabled {
    color: var(--gray-46);
}

.summary__subchapter-link--active {
    font-weight: 600;
}

.summary__subchapter-link--has-warnings {
    --status-color: var(--yellow);
}

.summary__subchapter-link--has-errors {
    --status-color: var(--red);
}

.summary__list-item--disabled {
    color: var(--gray-90);

    .summary__subchapters,
    .summary__status-icon {
        display: none;
    }

    .summary__status-icon--placeholder {
        display: block;
    }
}
