/* ================
 BLOCK
================= */

.value-display {
    --error-color: var(--red);
    --border-color: var(--gray-90);
    --label-color: var(--gray-46);
    position: relative;
    padding-bottom: .5rem;
    display: grid;
    grid: 1rem 1.5rem / 1fr auto;
    grid-template-areas:
        'label label'
        'value icon-toggle';
    gap: 0 1rem;
    border-bottom: 1px solid var(--border-color);

    &:focus-within {
        border-bottom-color: var(--blue-light);
        box-shadow: 0 1px var(--blue-light);
    }
}

/* ================
 ELEMENTS
================= */

.value-display__value {
    grid-area: value;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.value-display__label {
    grid-area: label;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(1rem);
    transform-origin: left top;
    transition: all var(--transition-duration) ease-out;
    color: var(--label-color);
    line-height: 1.5;
    pointer-events: none; /*transparent for clicks*/

    /*move label out of the way when values are present*/

    .value-display__value:not(:empty) ~ & {
        transform: scale(.75);
    }
}

.value-display__icon {
    grid-area: icon-toggle;
    place-self: center;
    width: 1.5rem;
    height: auto;
}

/* ================
 MODIFIER(S)
================= */

.value-display__icon--autofill {
    color: var(--green);
}
