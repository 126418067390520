/* ================
 BLOCK
================= */

.dashboard-nav {
    text-transform: lowercase;
    z-index: 100;
    font-size: var(--menu-font-size);
}

/* ================
 ELEMENTS
================= */

.dashboard-nav,
.dashboard-nav__inline,
.dashboard-nav__bar-items {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.dashboard-nav__main-item,
.dashboard-nav__bar-item {
    flex: 0 0 auto;
    position: relative;
}

.dashboard-nav__bar {
    background: var(--bg);
    color: #FFFFFF;
    position: static;
    margin-bottom: var(--target-size);
}

.dashboard-nav__bar-items::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 33.333vw;
    background: var(--bg);
    box-shadow: -33.333vw 0 var(--bg), 33.333vw 0 var(--bg);
}

.dashboard-nav__bar-heading {
    margin: 0;
}

.dashboard-nav__bar-toggle {
    background: inherit;
    color: inherit;
    border: 0;
    font-weight: bold;
    font-size: var(--menu-font-size);
    font-family: inherit;
    line-height: 1.5;
    padding: var(--target-padding);
    cursor: pointer;
    text-transform: inherit;
    margin: 0;
}

.dashboard-nav__bar-toggle:focus-visible {
    background: #FFFFFF;
    color: var(--bg);
    box-shadow: inset 0 0 0 .25ch currentColor;
}

.dashboard-nav__bar-items {
    position: absolute;

    /* top: 100%; */
    left: 0;
    right: 0;
    background: inherit;
}

.dashboard-nav__bar-item .nav-dropdown__heading {
    color: #FFFFFF;
}

.dashboard-nav__link {
    padding: var(--target-padding);
    text-decoration: none;
    line-height: 1.5;
    display: inline-block;
}

.dashboard-nav__link:hover,
.dashboard-nav__link:focus {
    text-decoration: underline;
}

/* ================
 MODIFIER(S)
================= */

.dashboard-nav__main-item--rent,
.dashboard-nav__main-item--sale {
    order: -10;
}

.dashboard-nav--rent-first .dashboard-nav__main-item--rent,
.dashboard-nav--sale-first .dashboard-nav__main-item--sale {
    order: -11;
}

.dashboard-nav__bar--sale {
    --bg: var(--blue-dark);
}

.dashboard-nav__bar--rent {
    --bg: var(--blue-light);
}

.dashboard-nav__main-item--push-right {
    margin-left: auto;
}

.dashboard-nav__main-item--bg-white {
    background: #FFFFFF;
}

@media screen and (max-width: 640px) {
    /* I do not ask for your forgiveness, I ask for your understanding */

    .dashboard-header .wrapper {
        padding: 0;
    }

    .dashboard-nav {
        max-width: 100dvw;
    }

    .dashboard-nav__bar {
        position: relative;
        margin: 0;
    }

    .dashboard-nav__bar-items {
        display: none;
        position: fixed;
        top: var(--target-size, 44px);
        inset-inline: 0;
        z-index: 1000;
    }

    .dashboard-nav__main-item:is(:hover, :focus-within) {
        .dashboard-nav__bar-items {
            display: block;
        }
    }

    .dashboard-nav__main-item:has(.utils) {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10001;
        background: linear-gradient(to right, transparent, white 1ch);
    }

    .utils__item:has(a:where([href*="keyloans.aspx"], [href*="leadflow"]), .sso-link, #news),
    li:has(> .client-dropdown),
    .dashboard-nav__main-item--bg-white {
        display: none;
    }

    .utils__link {
        min-width: 0 !important;
        padding-inline: 1ch !important;
    }

    .dashboard-nav__main-item:has(.current-user) {
        display: block;
        margin: 0;
    }

    .current-user {
        .nav-dropdown__list {
            position: fixed;
            top: var(--target-size, 44px);
            inset-inline: 0;
            width: 100%;
            z-index: 1000;
        }
    }

    .user-dropdown {
        overflow: auto;
    }
}
