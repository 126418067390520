/* ================
 TRANSITIONS
================= */

:root {
    --transition-duration: 150ms;
    --transition-easing-function: ease-in-out;
    --transition-easing-bounce: cubic-bezier(.17, .67, .64, 1.19);
    --transition-base: all var(--transition-duration) var(--transition-easing-function);
}

@media (prefers-reduced-motion) {
    :root {
        --transition-duration: 0;
    }
}
