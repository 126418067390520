/* ================
 BLOCK
================= */

.tag {
    position: relative;
    display: inline-block;
    padding: calc((var(--target-size-minimum) - 1em) / 2);
    padding-inline-start: var(--target-size-minimum);

    min-width: var(--target-size-minimum);
    max-width: 30ch;
    min-height: var(--target-size-minimum);

    border-radius: var(--border-radius-base);
    border: 1px solid currentColor;

    background: white;

    font-size: .75rem;
    line-height: 1;
}

.tag:focus-visible::before{
    content: '';
    position: absolute;
    inset: -5px;
    border: 2px solid currentColor;
    opacity: .25;
    border-radius: calc(var(--border-radius-base) + 2px);
}

/* ================
 ELEMENTS
================= */

.tag__icon {
    position: absolute;
    top: 50%;
    inset-inline-start: calc(var(--target-size-minimum) / 2);
    transform: translate(-50%, -50%);
    width: 1em;
    height: 1em;
    color: var(--gray-20);
}

.tag:where(:hover, :focus) .tag__icon {
    color: currentColor;
}
