.user-background-image {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: -1;
}

@supports (animation-timeline: scroll(root block)) {
    .user-background-image {
        transform: scale(1.005);
        animation: move-bg linear;
        animation-timeline: scroll(root block);
    }
}

@keyframes move-bg {
    from {
        transform: scale(1.01) translateY(.25%);
    }

    to {
        transform: scale(1.01) translateY(-.25%);
    }
}
