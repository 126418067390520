/* ================
 BLOCK
================= */

.address {
    --geo-start-row: 13;

    grid-template-areas:
        'ctry ctry ctry ctry ctry ctry'
        'pstl pstl pstl .    .    .'
        'hnum hnum hnum sufx sufx sufx'
        'hnuk hnuk hnuk hnuk hnuk hnuk'
        'sfxw sfxw sfxw sfxw sfxw sfxw'
        'adrs adrs adrs adrs adrs adrs'
        'dstr dstr dstr dstr dstr dstr'
        'ngbh ngbh ngbh ngbh ngbh ngbh'
        'warn warn warn warn warn warn'
        'strt strt strt city city city'
        '_map _map _map _map _map _map'
        '_lat _lat _lat long long long'
        'rors rors rors rors rors rors';

    /*make sure hidden inputs don't take up space*/
    grid-row-gap: 0;

    & > div:not(:first-of-type) {
        margin-top: var(--form-grid-gap);
    }

    .form-row {
        width: 100%;
    }

    /*lat n long need some extra attention*/

    .coordinates__latitude,
    .coordinates__longitude {
        width: 100%;
        margin-top: var(--form-grid-gap);
    }

    /*assign inputs to grid area*/

    .address__country {
        grid-area: ctry;
    }

    .address__postal-code {
        grid-area: pstl;
    }

    .house-number__house-number {
        grid-area: hnum;
    }

    .house-number__house-number-unnumbered {
        font-size: .875rem;
        grid-area: hnuk;
        margin-bottom: calc(-.5 * var(--form-grid-gap));
    }

    .house-number__house-number-suffix {
        grid-area: sufx;
    }

    .address__room-number,
    .address__parking-number {
        grid-area: unnr;
    }


    .coordinates__latitude {
        grid-area: _lat;
    }

    .coordinates__longitude {
        grid-area: long;
    }

    .address__street {
        grid-area: strt;
    }

    .address__city {
        grid-area: city;

        @media screen and (max-width: 480px) {
            margin-top: var(--form-grid-gap);
        }
    }

    @media screen and (min-width: 70ch) {
        --geo-start-row: 11;

        grid-template-areas:
            'ctry ctry ctry .    .    .'
            'pstl pstl hnum hnum sufx sufx'
            '.    .    hnuk hnuk hnuk hnuk'
            'sfxw sfxw sfxw sfxw sfxw sfxw'
            'adrs adrs adrs adrs adrs adrs'
            'dstr dstr dstr dstr dstr dstr'
            'ngbh ngbh ngbh ngbh ngbh ngbh'
            'warn warn warn warn warn warn'
            'strt strt strt city city city'
            '_map _map _map _map _map _map'
            '_lat _lat _lat long long long'
            'rors rors rors rors rors rors';
    }
}

/* ================
 ELEMENTS
================= */

.address__display--address {
    grid-area: adrs;
}

.address__display--neighbourhood {
    grid-area: ngbh;
}

.address__display--district {
    grid-area: dstr;
}

.address__map-container,
.address__warning,
.address__geo-button {
    width: 100%;
    margin-top: var(--form-grid-gap);
}

.address__map-container {
    grid-area: _map;
    min-height: min(50vh, 450px);
    display: grid;
    place-items: center;
    background: var(--gray-96);
    border-radius: var(--border-radius-base);
    padding: 1ch;
}

.address__warning {
    grid-area: warn;
    margin: calc(1.5 * var(--form-grid-gap)) 0;
}

.address__geo-button {
    min-height: var(--input-min-height);
}

/* ================
 MODIFIER(S)
================= */

.address__geo-button--error {
    --btn-bg-color: var(--red-dark) !important;
    pointer-events: none;
}

.address--has-unit-number {
    --geo-start-row: 14;

    grid-template-areas:
        'ctry ctry ctry ctry ctry ctry'
        'pstl pstl pstl .    .    .'
        'hnum hnum hnum sufx sufx sufx'
        'hnuk hnuk hnuk hnuk hnuk hnuk'
        'sfxw sfxw sfxw sfxw sfxw sfxw'
        'unnr unnr unnr unnr unnr unnr'
        'adrs adrs adrs adrs adrs adrs'
        'dstr dstr dstr dstr dstr dstr'
        'ngbh ngbh ngbh ngbh ngbh ngbh'
        'warn warn warn warn warn warn'
        'strt strt strt city city city'
        '_map _map _map _map _map _map'
        '_lat _lat _lat long long long'
        'rors rors rors rors rors rors';

    @media screen and (min-width: 70ch) {
        --geo-start-row: 12;

        grid-template-areas:
            'ctry ctry ctry .    .    .   '
            'pstl pstl hnum hnum sufx sufx'
            '.    .    hnuk hnuk hnuk hnuk'
            'sfxw sfxw sfxw sfxw sfxw sfxw'
            'unnr unnr unnr .    .    .   '
            'adrs adrs adrs adrs adrs adrs'
            'dstr dstr dstr dstr dstr dstr'
            'ngbh ngbh ngbh ngbh ngbh ngbh'
            'warn warn warn warn warn warn'
            'strt strt strt city city city'
            '_map _map _map _map _map _map'
            '_lat _lat _lat long long long'
            'rors rors rors rors rors rors';
    }

    /*remove this as soon as container queries have a (somewhat) widespread adoption*/
    @media screen and (min-width: 1074px) {
        grid-template-areas:
            'ctry ctry ctry .    .    .   '
            'pstl pstl hnum hnum sufx sufx'
            '.    .    hnuk hnuk hnuk hnuk'
            'sfxw sfxw sfxw sfxw sfxw sfxw'
            'unnr unnr .    .    .    .   '
            'adrs adrs adrs adrs adrs adrs'
            'dstr dstr dstr dstr dstr dstr'
            'ngbh ngbh ngbh ngbh ngbh ngbh'
            'warn warn warn warn warn warn'
            'strt strt strt city city city'
            '_map _map _map _map _map _map'
            '_lat _lat _lat long long long'
            'rors rors rors rors rors rors';
    }

    .house-number__house-number-unnumbered {
        margin-bottom: 0;
    }
}

.address--has-geolocation {

    .address__geo-button {
        grid-area: var(--geo-start-row) / 1 / calc(var(--geo-start-row) + 1) / -1;
    }

    .address__geo-error {
        margin-top: var(--form-grid-gap);
        grid-area: calc(var(--geo-start-row) + 1) / 1 / calc(var(--geo-start-row) + 2) / -1;
    }

    @media screen and (min-width: 70ch) {

        .address__geo-button {
            grid-area: var(--geo-start-row) / 5 / calc(var(--geo-start-row) + 1) / 7;
        }

        .address__geo-error {
            grid-area: calc(var(--geo-start-row) + 1) / 1 / calc(var(--geo-start-row) + 2) / -1;
        }

        .coordinates__latitude {
            grid-area: var(--geo-start-row) / 1 / calc(var(--geo-start-row) + 1) / 3;
        }

        .coordinates__longitude {
            grid-area: var(--geo-start-row) / 3 / calc(var(--geo-start-row) + 1) / 5;
        }
    }
}
