/* ================
 BLOCK
================= */

.messages-list-item {
    --messages-list-item-button-size: 11px;

    position: relative;
    color: #333333;
    padding: 7px 0;

    &:not(:last-child) {
        border-bottom: 1px solid #EEEEEE;
    }
}

/* ================
 ELEMENTS
================= */

.messages-list-item__content-container {
    padding-right: calc(var(--messages-list-item-button-size) + 8px);
    display: flex;

    a {
        display: inline !important;
        padding: 0 !important;
    }
}

.messages-list-item__image {
    width: 39px;
    height: 39px;
    flex: 0 0 auto;
    margin-right: 8px;
}

.messages-list-item__heading {
    color: #42569E;
    font-size: 1em;
    font-weight: normal;
    margin: 0;

    button {
        display: inline;
        background: none;
        padding: 0;
        border: 0;
        color: inherit;

        &:hover,
        &:focus {
            color: #444444;
        }
    }
}

.messages-list-item__content {
    flex: 1 1 auto;
}

.messages-list-item__age {
    margin-top: 4px;
    font-size: 10px;
    color: #666666;
}

.messages-list-item__delete {
    --message-delete-color: #B0BDCF;
    --message-delete-active-color: #5F7FAD;

    position: absolute;
    top: 7px;
    right: 0;
    width: var(--messages-list-item-button-size);
    height: var(--messages-list-item-button-size);
    padding: 0;
    border: 0;
    background: #FFFFFF;
    color: var(--message-delete-color);
    display: none;
    align-items: center;
    justify-content: center;

    .messages-list-item:hover &,
    .messages-list-item:focus & {
        display: flex;
    }

    &:hover,
    &:focus {
        background: var(--message-delete-color);
        color: #FFFFFF;
    }

    &:active {
        background: var(--message-delete-active-color);
    }
}

.messages-list-item__delete-icon {
    width: 7px;
    height: 7px;
}
