.clearable-boolean {
    --br: calc(var(--border-radius-base) - 2px);
    display: grid;
    grid: auto / auto;
    gap: 1ch;
    align-items: center;
}

.clearable-boolean:first-of-type {
    margin-block-start: .5rem;
}

.clearable-boolean__label {
    font-size: 1rem;
    margin: 0 0 .5em;
    font-weight: normal;
}

.clearable-boolean__options {
    display: grid;
    grid: auto / 1fr 1fr auto;
    align-items: center;
    padding-block: 2px;
}

.clearable-boolean__options :first-child {
    border-radius: var(--br);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.clearable-boolean__options :nth-child(2) {
    border-radius: var(--br);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.clearable-boolean__option,
.clearable-boolean__clear {
    --size: calc(var(--target-size-minimum));
    --icon-size: 1.25em;
    width: auto;
    min-width: var(--size);
    min-height: var(--size);
    position: relative;
    cursor: pointer;
    display: grid;
    place-content: center;
}

.clearable-boolean__option {
    --bg: white;
    --fg: currentColor;
    --bc: currentColor;
    background: var(--bg);
    color: var(--fg);
    box-shadow: 0 0 0 1px var(--bc);
    padding: .25ch 1.5ch;
}

.clearable-boolean__clear {
    margin-inline-end: calc((var(--size) - var(--icon-size)) / -2);
    transform: scale(0);
    transition: all 150ms var(--transition-easing-bounce);
    pointer-events: none;
}

.clearable-boolean__clear:is(:hover, :focus) {
    color: var(--red-dark);
}

.clearable-boolean__options:has(:where([value="yes"]:checked, [value="no"]:checked)) .clearable-boolean__clear {
    transform: none;
    pointer-events: auto;
}

@media (any-pointer: fine) {
    .clearable-boolean__clear {
        margin-inline-start: calc(var(--card-padding) / 2);
    }
}

:where(.clearable-boolean__option, .clearable-boolean__clear) svg {
    width: var(--icon-size);
    height: var(--icon-size);
}

.clearable-boolean__option:not(.clearable-boolean__option--all):has(input:checked) {
    --bg: var(--blue);
    --bc: var(--bg);
    --fg: white;
    z-index: 1;
}

:where(.clearable-boolean__option, .clearable-boolean__clear):is(:hover, :focus) {
    --bg: var(--blue-light) !important;
    --bc: var(--bg);
    --fg: white;
    z-index: 2;
}

@container (width >= 250px) {
    .clearable-boolean {
        grid: auto / 1fr auto;
        gap: 1ch;
    }

    .clearable-boolean__legend {
        display: contents;
    }


    .clearable-boolean__label {
        margin: 0;
    }

    .clearable-boolean__options {
        display: inline-grid;
        width: 160px;
    }

    @media (any-pointer: fine) {
        .clearable-boolean__options {
            width: 147px;
        }
    }
}
