@use '../../00-variables/breakpoints' as breakpoints;
@use '../../01-mixins/mixins' as mixins;

/* ================
 BLOCK
================= */

$transition-duration: 200ms;
$transition-delay: 150ms;

.form-sidebar {
    position: sticky;
    top: calc(var(--header-height) + var(--card-padding));
    z-index: 1;

    .card {
        overflow: visible;
        position: relative;
    }

    @media screen and (max-width: breakpoints.$md) {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 2rem;
        transition: all $transition-duration $transition-delay;
        pointer-events: none;
        z-index: 100;
    }
}

/* ================
 ELEMENTS
================= */

.form-sidebar__inner {
    display: grid;
    grid: auto / 1fr;
    gap: var(--card-padding);

    @media screen and (max-width: breakpoints.$md) {
        @include mixins.card;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(100vw, -50%);
        transition: transform $transition-duration ease-in;
        overflow: auto;
        min-width: min(45ch, calc(100vw - 4rem));
        max-height: calc(100vh - 4rem);

        .form-sidebar--open & {
            transform: translate(-50%, -50%);
            transition-delay: $transition-delay;
        }

        .form-sidebar__card {
            position: relative;
            background: none;
            padding: 0;
            border-radius: 0;
            box-shadow: none;
            overflow: visible;
            padding-inline-end: var(--card-close-button-size);

            &:first-of-type {
                margin-block-start: calc(-1 * var(--card-close-button-size));
            }

            &:not(:first-of-type) {
                padding-block-start: var(--card-padding);

                &::before {
                    content: '';
                    position: absolute;
                    bottom: 100%;
                    left: calc(var(--card-padding) * -1);
                    right: calc(var(--card-padding) * -1);
                    border-top: 1px solid var(--gray-90);
                }
            }
        }
    }
}

.form-sidebar__close {
    position: sticky;
    top: calc(var(--card-padding) * -1);
    left: 100%;
    margin: calc(var(--card-padding) * -1);
    margin-block-end: 0;
    display: none;
    grid-area: unset;
    z-index: 1;

    @media screen and (max-width: breakpoints.$md) {
        display: inline-grid;
    }
}

.form-sidebar__header {
    position: relative;
}

.form-sidebar__heading {
    margin: 0;
    width: 100%;
    flex: 0 0 auto;
    font-weight: 600;

    &:is(h2) {
        font-weight: bold;
    }
}

.form-sidebar__subheading {
    font-size: .75rem;
    margin: 0;

    &:empty {
        display: none;
    }
}

.form-sidebar__notes,
.form-sidebar__tags {
    margin-block-start: var(--card-padding);
    font-size: .875rem;
}

.form-sidebar__tags {
    display: flex;
    flex-wrap: wrap;
    gap: 1ch;
}

.form-sidebar__button-container {
    margin-block-start: var(--card-padding);

    & > .btn {
        width: 100%;
        margin-block-start: var(--card-padding);

        &:first-child {
            margin-block-start: 0;
        }
    }
}

.form-sidebar__link {
    &:focus,
    &:hover {
        text-decoration: underline;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.form-sidebar__date {
    margin-block-start: 1rem;
    font-size: .75em;
    color: var(--gray-73);
    text-align: end;
}

/* ================
 MODIFIER(S)
================= */

.form-sidebar--open {
    @media screen and (max-width: breakpoints.$md) {
        @include mixins.backdrop-blur;
        pointer-events: auto;
        background: rgba(0, 0, 0, 0.5);
        transition-delay: 0ms;
    }
}

.form-sidebar__partial--block {
    display: block;
    margin-block-start: .5em;
}
