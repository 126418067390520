/* ================
 BLOCK
================= */

.edr {
    display: grid;
    container-type: inline-size;
}

/* ================
 ELEMENTS
================= */

.edr__score {
    --score-color: var(--gray-73);
    text-align: center;
    font-weight: 700;
    font-size: 1.5rem;
    font-size: clamp(1.5rem, 3vi, 2.5rem);
    padding-block-end: .5em;
    width: 100%;
}

.edr__score span {
    color: var(--score-color);
}

.edr__all-scores {
    justify-self: center;
    font-size: .75rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1ch;
}

.edr__score-type {
    display: inline-grid;
    grid: auto / auto auto;
    gap: .5ch;
}

.edr__score-type dd {
    font-weight: 600;
}

.edr__summary {
    font-size: .875rem;
    margin: 1em 0 0;
    justify-self: center;
}

.edr__main-observations {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    margin: 1.5em 0 .75em;
    align-items: flex-start;
    justify-content: center;
}

.edr__main-observations:empty {
    display: none;
}

.edr__heading {
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
}

.edr__amount {
    font-weight: 600;
}

.edr__all-observations-link {
    grid-column: 1/-1;
    font-size: .875rem;
    display: block;
    margin: 0 auto 1em;
}

:empty + po-dialog .edr__all-observations-link {
    margin-block: .25em;
}

.edr__all-observations,
.edr__observation-group {
    display: grid;
    grid: auto/repeat(auto-fill, minmax(40ch, 1fr));
    gap: 1rem 2rem;
}

.edr__observation-group {
    grid-column: 1 / -1;
}

.edr__observation-group--small {
    grid-column: auto / span 1;
}

.edr__observation-group:not(:last-of-type) {
    margin-block-end: 1.5rem;
}

.edr__observation-group-heading {
    grid-column: 1/-1;
    margin: 0;
    font-size: 1.25rem;
    font-weight: 600;
}

.edr__observation {
    display: flex;
    gap: .75ch;
    align-items: flex-start;
    margin-block-start: .125em;
}

.edr__observation--small {
    font-size: .875rem;
}

.edr__observation--align-amount {
    justify-content: space-between;
}

.edr__observation--nothing-found {
    color: var(--gray-73);
}

.edr__observation--nothing-found .edr__amount {
    font-weight: normal;
}

.edr__footer {
    font-size: .75rem;
    color: var(--gray-46);
}

.edr__footer time {
    font-weight: 600;
}

.edr__footer p {
    max-width: none;
}

.edr__language-warning {
    display: none;
    margin-block-start: var(--card-padding);
    font-size: .75rem;
    color: var(--gray-46);
}

.edr__language-warning::before {
    content: '*';
}

.edr__language-warning:not(:empty) {
    display: block;
}

po-edr po-edr {
    display: none !important;
}

/* ================
 MODIFIER(S)
================= */

.edr__score--red {
    --score-color: var(--red);
}

.edr__score--orange {
    --score-color: var(--orange-dark);
}

.edr__score--green {
    --score-color: var(--green);
}

@container (min-width: 50ch) {
    .edr__observations {
        grid: auto / 1fr 1fr;
        justify-self: center;
    }

    .edr__all-observations-link {
        justify-self: center;
    }

    .edr__footer {
        text-align: center;
    }
}
