.form-errors__list {
    list-style: none;
    margin: 0;

    li + li {
        margin-block-start: calc(var(--card-padding) / 4);
    }
}

.form-errors__link:is(:hover, :focus) {
    text-decoration: dotted underline;
}

.form-errors__field-name {
    font-weight: 600;
}
