/* ================
 BORDERS
================= */

:root {
    --border-base: 1px solid var(--gray-46);

    /* radii */
    --border-radius-small: 2px;
    --border-radius-base: 4px;
    --border-radius-large: 8px;
}
