/* ================
 BLOCK
================= */

.nav-dropdown {
    position: relative;
}

/* ================
 ELEMENTS
================= */

.nav-dropdown__heading {
    font-weight: normal;
    font-size: inherit;
    color: inherit;
    margin: 0;
    position: relative;
    z-index: 101;
    border: 1px solid transparent;
    line-height: 1.5;
    cursor: default;
}

.nav-dropdown__heading[data-notifications]:not([data-notifications='0'])::after {
    font-size: .75em;
    content: attr(data-notifications);
    background: var(--red-dark);
    color: #FFFFFF;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    display: inline-grid;
    place-items: center;
    vertical-align: middle;
    margin-left: 1ch;
}

.nav-dropdown__heading-icon {
    display: inline-block;
    vertical-align: middle;
    width: 1rem;
    height: 1rem;
    object-fit: contain;
    object-position: center;
}

.nav-dropdown__heading-label:not(:first-child) {
    display: inline-block;
    vertical-align: middle;
    margin-left: 1ch;
}

.nav-dropdown:not(.nav-dropdown--no-items):hover .nav-dropdown__heading,
.nav-dropdown:not(.nav-dropdown--no-items):focus-within .nav-dropdown__heading {
    background: #FFFFFF;
    color: #000000;
    border-color: #000000;
    border-bottom: 0;
    box-shadow: 0 2px 0 -1px #FFFFFF;
}

.nav-dropdown__heading-link,
.nav-dropdown__link,
.nav-dropdown__message {
    text-decoration: none;
    color: inherit;
    display: block;
    font-size: .875rem;
    padding: var(--target-padding);
}

.nav-dropdown__heading-link,
.nav-dropdown__message {
    display: inline-block;
    line-height: inherit !important;
    padding: calc((var(--target-size) - 1.5em) / 2 - 1px) 1em;
}

.nav-dropdown__heading-label {
    color: inherit;
}

.nav-dropdown__list {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    background: #FFFFFF;
    color: var(--gray-20);
    display: none;
    list-style-type: none;
    border: 1px solid #000000;
    max-height: 400px;
    overflow-x: hidden;
    overflow-y: auto;
}

.nav-dropdown__link {
    white-space: nowrap;
    line-height: 1.5 !important;
}

.nav-dropdown__link:focus,
.nav-dropdown__link:hover {
    background: var(--gray-96);
    color: #000000;
}

.nav-dropdown:not(.nav-dropdown--no-items):hover,
.nav-dropdown:not(.nav-dropdown--no-items):focus-within {
    z-index: 300;
}

.nav-dropdown:not(.nav-dropdown--no-items):hover .nav-dropdown__list,
.nav-dropdown:not(.nav-dropdown--no-items):focus-within .nav-dropdown__list {
    display: block;
}

/* ================
 MODIFIER(S)
================= */

.nav-dropdown--align-right .nav-dropdown__list {
    left: auto;
    right: 0;
}

.nav-dropdown__heading--no-link {
    padding: calc((var(--target-size) - 1.5em) / 2 - 1px) 1em;
}

.nav-dropdown__heading--hide-label {
    text-align: center;
    padding-inline: .5ch;
    min-width: var(--target-size);
}
