@use '../../01-mixins/mixins' as mixins;

/* ================
 BLOCK
================= */

.fieldset {
    position: relative;

    &:not(:last-child) {
        margin-bottom: .25rem;
    }
}

/* ================
 ELEMENTS
================= */

.fieldset__legend {
    display: contents;
    width: 100%;

    .fieldset--hide-legend & {
        @include mixins.sr-only;
    }
}

.fieldset__heading {
    font-size: 1.125rem;
    max-width: none;
    margin: 0;
    margin-bottom: var(--card-padding);
    line-height: 1;
    grid-column: 1/-1;

    :disabled & {
        color: var(--gray-46);
    }

    :not(.fieldset--no-collapse):disabled & {
        margin-bottom: 0;
    }
}

.fieldset__heading-icon {
    width: 1.5rem;
    aspect-ratio: 1;
    margin-inline-start: 1ch;
}

.fieldset__toggle {
    font-size: inherit;
    width: 100%;
    padding-block: calc((var(--target-size) - 2rem) / 2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .toggle-indicator {
        flex: 0 0 auto;
        margin-left: auto;
    }

    [data-locked] & {
      cursor: default;
    }
}

.fieldset__autofill-message {
    display: none;

    .fieldset:has([data-autofill]) > .fieldset__content & {
        display: grid;
    }
}

.fieldset__content,
.fieldset--wrap > div {
    display: grid;
    gap: 1rem;
    grid: auto / 1fr;
    max-width: 70ch;

    .fieldset--has-toggle:not(.fieldset--no-collapse):disabled & {
        display: none;
    }
}

.fieldset__warning {
    max-width: 93.333ch; // 70ch / 0.75rem
}

.fieldset__action-button {
    position: absolute;
    top: 0;
    right: 0;
}

/* ================
 MODIFIER
================= */

.fieldset--has-toggle {
    padding-block: calc((3.5rem - var(--target-size)) / 2);

    &:not(:disabled) {
        padding-block-end: var(--card-padding);
    }
}

.form-grid .fieldset:not(:first-of-type) .fieldset__heading--no-toggle,
.fieldset .fieldset .fieldset__heading--no-toggle {
    margin-block-start: 1em;
}

.fieldset .fieldset .fieldset__heading--no-toggle {
    font-weight: 600;
}
