/* ================
 BLOCK
================= */

.messages-dropdown {
    position: relative;
    z-index: 2000;
}

/* ================
 ELEMENTS
================= */

.messages-dropdown__heading {
    font-size: 1em;
    font-weight: inherit;
    margin: 0;
}

.messages-dropdown__button {
    position: relative;
    cursor: pointer;
    border: 1px solid transparent;
    border-bottom: 0;
    font-weight: bold;
    line-height: 1.5;
    padding: calc((var(--target-size) - 1.5em) / 2 - 1px) .5em;
    margin-right: 1ch;
    background: none;
    color: #666666;
    display: flex;
    align-items: flex-start;
    overflow: visible;

    &[aria-expanded='true'] {
        background: #FFFFFF;
        border-color: #111111;
        box-shadow: 0 2px 0 -1px #FFFFFF;
    }
}

.messages-dropdown__icon {
    align-self: center;
    display: inline-block;
    width: 1em;
    height: 1em;
    margin: .25em 0;
    flex: 0 0 auto;
}

.messages-dropdown__button-label {
    display: inline-block;

    &:not(:first-child) {
        margin-left: 1ch;
    }
}

.message-popup__message-indicator {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(50%);
    font-size: .75em;
    font-weight: normal;
    min-width: 1.25em;
    height: 1.25em;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--red-dark);
    color: #FFFFFF;

    [aria-expanded='true'] & {
        display: none;
    }
}

.messages-dropdown__popup {
    position: absolute;
    top: 100%;
    left: 0;
    width: 312px;
    height: auto;
    background: #FFFFFF;
    z-index: -1;
    color: #111111;
    font-size: 12px;
    border: 1px solid #111111;
    padding: 7px 10px;

    &[aria-expanded='false'] {
        display: none;
    }
}
