:empty:has(+ .empty-section-message) {
    display: none;
}

:not(:empty) + .empty-section-message {
    display: none;
}

.empty-section-message {
    font-size: .875rem;
    font-style: italic;
}
