/* ================
 UMBRELLA LINK
================= */

.umbrella {
    position: static;
    color: inherit;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
