@mixin sr-only {
    display: unset;
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
}

@mixin backdrop-blur($blur: 2px, $background-color: rgba(0, 0, 0, 0.25), $background-color-no-support: rgba(128, 128, 128, 0.75)) {
    background: $background-color-no-support;

    @supports (backdrop-filter: blur($blur)) {
        backdrop-filter: blur($blur);
        background: $background-color;
    }

    .unix-like & {
        backdrop-filter: none !important;
        background: $background-color-no-support;
    }
}

@mixin center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin card {
    padding: var(--card-padding);
    border-radius: var(--card-border-radius);
    border: 1px solid var(--gray-96);
    background: #FFFFFF;
    overflow: hidden;
}

@mixin abs-rent-spec {
    width: var(--rent-spec-width, 100%);
    grid-column: none !important;
    position: absolute;
    margin: 0 !important;
    top: 0;
    bottom: 0;
    right: var(--form-grid-gap);
    height: 100%;
}
