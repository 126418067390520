.language-switch {
    --size: var(--btn-target-size);
    all: unset;
    box-sizing: border-box;
    min-width: var(--size);
    min-height: var(--size);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius-base);
    cursor: pointer;
    border: 1px solid var(--gray-96);
}

.dashboard-nav .language-switch {
    --size: 2rem;
    border: none;
}

.language-switch:where(:hover, :focus) {
    border-color: var(--gray-46);
}

.language-switch__icon {
    width: 1rem;
    height: 1rem;
}
