@use '../../00-variables/breakpoints' as breakpoints;

/* ================
 BLOCK
================= */

.layout {
    // empty for reference
}

/* ================
 ELEMENTS
================= */
@media (min-width: breakpoints.$lg) {
    .layout__number-of-rooms,
    .layout__number-of-bedrooms {
        grid-row-start: 2 !important;
    }
}
