@use '../00-variables/breakpoints' as breakpoints;

/* ================
 ELEMENTS
================= */

.sidebar-form__button-bar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    transition: all 150ms;
    gap: var(--form-grid-gap);
}

.sidebar-form__submit-messages {
    margin-inline-start: auto;
}

.sidebar-form__open {
    --btn-bg-color: var(--green);
    display: none;

    &:focus,
    &:hover {
        --btn-bg-color: var(--green-dark);
    }
}

@media screen and (max-width: breakpoints.$md) {
    .sidebar-form__button-bar {
        justify-content: space-between;
        position: sticky;
        top: auto;
        bottom: 0;
        z-index: 1;
        width: 100%;

        &.stuck,
        &:not(.stuck--init) {
            width: 100vw;
            margin-left: 50%;
        }

        &:not(.stuck--init) {
            transform: translate(-50%, 100%);
        }

        &.stuck {
            transform: translateX(-50%);
            background: #FFFFFF;
            padding: var(--card-padding);
            box-shadow: var(--shadow-huge);
        }
    }

    .sidebar-form__open {
        display: flex;
    }

    .sidebar-form__submit-messages {
        display: none;
    }
}
