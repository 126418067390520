/* ================
 BLOCK
================= */

.client-dropdown {
    /*empty for reference*/
}

/* ================
 ELEMENTS
================= */

.client-dropdown__input {
    float: none;
    font-size: 1em;
    border-radius: 0;
    padding: var(--target-padding);
    border: 0;
    letter-spacing: 0;
    line-height: 1.5;
    box-sizing: border-box;
    width: 100%;
    background: #EEEEEE;

    &:hover {
        background: #E7E7E7;
    }

    &:focus {
        background: #DDDDDD;
    }
}

/* ================
 MODIFIER(S)
================= */

.client-dropdown__client--demo {
    font-style: italic;
}

.client-dropdown__client--current {
    font-weight: bold;
}

.client-dropdown--has-selection .client-dropdown__client--current,
.client-dropdown--has-selection li:not(.client-dropdown__client--selected) strong {
    font-weight: normal !important;
}

.client-dropdown--has-selection .client-dropdown__client--selected {
    font-weight: bold !important;
}

.client-dropdown__client--inactive {
    color: var(--gray-90) !important;
}
