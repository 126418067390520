/* ================
 BLOCK
================= */

.browser-warning {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(128, 128, 128, 0.95);
    display: grid;
    place-items: center;
    padding: 2rem;
}

/* ================
 ELEMENTS
================= */

.browser-warning__inner {
    max-height: calc(100vh - 4rem);
    overflow: auto;
}

.browser-warning__heading {
    padding: calc((3rem - 1em) / 2) 0;
}

.browser-warning__description {
    margin-bottom: 0;
    line-height: 1.5;
}

.browser-warning__options {
    margin: 0;
    padding: 0;
    padding-left: 2ch;
    display: block;
    list-style: disc;
}

.browser-warning__option + .browser-warning__option {
    margin-top: 0.25rem;
}

body:not(.mac) .browser-warning__option--safari {
    display: none;
}
