/* ================
 BLOCK
================= */

.toggle-indicator {
    --background-color: var(--gray-90);
    --toggle-indicator-color: var(--blue-dark);
    --toggle-indicator-background-color: #FFFFFF;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 3.5rem;
    height: var(--toggle-indicator-height);
    border-radius: 1rem;
    background: var(--background-color);
    overflow: hidden;
}

/* ================
 ELEMENTS
================= */

.toggle-indicator__inner {
    position: absolute;
    top: 50%;
    left: .25rem;
    transform: translateY(-50%);
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background: var(--toggle-indicator-background-color);
    transition: all var(--transition-duration) ease-out;
    color: var(--toggle-indicator-color);
}

.toggle-indicator__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none !important;
}

/* ================
 MODIFIER(S)
================= */

.toggle-indicator__icon--cross {
    display: block;
}

.toggle-indicator {

    /*on states*/
    :checked ~ &,
    [aria-expanded='true'] &,
    .fieldset--has-toggle:not(:disabled) > legend &,
    .fieldset-group:not(:disabled) > legend & {
        --background-color: var(--blue-dark);

        .toggle-indicator__inner {
            transform: translateX(3rem) translate(-100%, -50%);
        }

        .toggle-indicator__icon--cross {
            display: none;
        }

        .toggle-indicator__icon--check {
            display: block;
        }
    }

    /*focus*/
    input:focus-visible ~ & .toggle-indicator__inner {
        color: var(--blue-light);
        box-shadow: 0 0 5px 2px var(--blue-light);
    }

    /*disabled*/
    input:disabled ~ &,
    [data-locked] & {
        --background-color: var(--gray-96) !important;
        opacity: .5;
    }

    /*error*/
    input:invalid ~ & {
        --background-color: var(--red-light);
        --toggle-indicator-color: var(--red-light);
        --toggle-indicator-background-color: var(--red);
    }
}


