/* ================
 BLOCK
================= */

.rent {
    --rent-spec-width: 40ch;

    .fieldset__content,
    .form-grid {
        max-width: none;
    }

    .tribool {
        --legend-width: 21ch;

        .choice__label {
            white-space: nowrap;
        }
    }
}

/* ================
 ELEMENTS
================= */

.rent__grid {
    --pr: var(--card-padding);
    position: relative;
    max-width: none;
    padding-right: var(--pr);
}

@media screen and (min-width: 1366px) {
    .rent__grid {
        --pr: calc(var(--rent-spec-width) + 3 * var(--form-grid-gap));
    }
}
