/* ================
 TYPOGRAPHY
================= */

:root {
    --line-width: 75ch;
}

body {
    font-family: 'Open Sans', sans-serif;
    line-height: 1.5;
    color: var(--gray-07);
}

.dashboard-header,
.footer,
.nomis-tabs {
    font-family: Helvetica, Arial, Tahoma, Verdana, sans-serif;
    color: var(--text-color);
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    max-width: var(--line-width);
    font-weight: bold;
    line-height: 1.25;
    font-size: 1rem;
    margin: -.125em 0 calc(1rem - .125em);
}

h1,
.h1 {
    font-size: 2rem;
}

h2,
.h2 {
    font-size: 1.5rem;
}

h3,
.h3 {
    font-size: 1.25rem;
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
    margin-bottom: calc(.5rem - .125em);
}

a {
    cursor: pointer;
}

a:not([class]) {
    position: relative;
    display: inline-block;
    vertical-align: baseline;
    padding: 1ch;
    margin: -1ch;
    color: var(--blue);
    text-decoration: dotted underline;

    &:is(:hover, :focus) {
        color: var(--blue-light);
        text-decoration: underline;
    }

    &:focus-visible {
        background: #FFFFFF;
        box-shadow: 0 0 1em #FFFFFF;
    }
}

p {
    max-width: var(--line-width);
    margin: -.25em 0 1em;

    &:last-child {
        margin-bottom: 0;
    }
}

:where(ul, ol):not([class]) {
    list-style: revert;
    margin: 0 0 1.5em 2ch;

    li:not(:last-child) {
        margin-bottom: .25em;
    }
}

legend {
    font-weight: 600;
}

b,
strong {
    font-weight: bold;
}

i,
em {
    font-style: italic;
}

/*typography helpers*/

.uppercase {
    text-transform: uppercase;
    letter-spacing: .05ch;
}

