/* ================
 BLOCK
================= */

.dialog {
    --dialog-backdrop-color: rgba(119, 119, 119, .7);

    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--dialog-backdrop-color);
}

/* ================
 ELEMENTS
================= */

.dialog__modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100vw - 4rem);
    max-width: 32rem;
    max-height: calc(100vh - 4rem);
    overflow: auto;
}

.dialog__header,
.dialog__footer {
    position: sticky;
    display: grid;
    gap: 1rem;
    z-index: 10;
    background: #FFFFFF;
}

.dialog__header {
    grid-template-columns: 1fr auto;
    align-items: start;
    padding-bottom: 1rem;
    top: 0;
    box-shadow: 0 -100vmax 0 100vmax #FFFFFF;
}

.dialog__heading {
    margin-bottom: -.125em;
    justify-self: start;
}

.dialog__footer {
    display: flex;
    align-items: start;
    justify-content: flex-end;
    padding-top: 1rem;
    gap: .5rem;
    bottom: 0;
    box-shadow: 0 100vmax 0 100vmax #FFFFFF;
}

/* ================
 MODIFIER(S)
================= */

.dialog--oldskool {

    .dialog__modal {
        border: 1px solid var(--gray-90);
        border-radius: 0;
        box-shadow: none;
    }

    .dialog__content {
        font-size: .875rem;
    }

    .dialog__footer {
        gap: .5rem;

        &::before {
            content: '';
            background: var(--gray-90);
            height: 1px;
            position: absolute;
            left: calc(-1rem + 2px);
            right: calc(-1rem + 2px);
            bottom: 100%;
        }
    }
}

.dialog--loading {

    .dialog__heading {
        background: currentColor;
    }

    .dialog__content {
        position: relative;
        overflow: hidden;
        height: 5rem;

        &::after {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            content: '';
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 50%;
            border: 4px solid var(--gray-46);
            border-left-color: transparent;
            animation: spinner 500ms linear infinite;
        }
    }
}

@keyframes spinner {

    from {
        transform: translate(-50%, -50%);
    }

    to {
        transform: translate(-50%, -50%) rotate(1turn);
    }
}
