/* ================
 COLORS
================= */

:root {
    --text-color: hsl(0, 0%, 20%);

    --blue-dark: hsl(203, 100%, 23%);
    --blue: hsl(203, 86%, 41%);
    --blue-light: hsl(203, 85%, 46%);
    --blue-lighter: hsl(205, 89%, 96%);

    --orange-dark: hsl(24, 100%, 43%);
    --orange: hsl(24, 100%, 50%);
    --orange-light: hsl(19, 82%, 93%);

    --green-dark: hsl(135, 40%, 36%);
    --green: hsl(144, 48%, 48%);
    --green-light: hsl(135, 40%, 98%);

    --gray-07: hsl(0, 0%, 7%);
    --gray-20: hsl(0, 0%, 20%);
    --gray-46: hsl(0, 0%, 46%);
    --gray-73: hsl(0, 0%, 73%);
    --gray-90: hsl(0, 0%, 90%);
    --gray-96: hsl(0, 0%, 96%);

    --red-dark: hsl(353, 85%, 36%);
    --red: hsl(353, 85%, 46%);
    --red-light: hsl(353, 89%, 96%);

    --yellow-dark: hsl(51, 100%, 30%);
    --yellow: hsl(51, 85%, 46%);
    --yellow-transparent: hsla(51, 85%, 46%, .25);
    --yellow-light: hsl(49, 89%, 96%);
}
