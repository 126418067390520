/* ================
 BLOCK
================= */

.description-list {
    container: description-list / inline-size;
}

/* ================
 ELEMENTS
================= */

.description-list dt {
    font-weight: 600;
}

.description-list > :where(dd, div):not(:last-child) {
    margin-block-end: .75em;
}

.description-list__link:where(:hover, :focus) {
    text-decoration: underline;
    text-decoration-style: dotted;
}

/* ================
 MODIFIER(S)
================= */

.description-list:empty {
    display: none;
}

@container (min-width: 40ch) {
    .description-list--inline div {
        display: grid;
        grid: auto / 1fr 1fr;
        gap: 0 1ch;
    }
}

.description-list--inline {
    max-width: 70ch;
}

.description-list__detail--inline {
    display: inline-block;

    & + & {
        margin-inline-start: 1rem;
    }
}
