/* ================
 BLOCK
================= */

.error-container {
    color: var(--red);
    width: 100%;
    flex: 0 0 auto;
    grid-column: 1 / -1;

    &:empty {
        display: none;
    }

    li:not(:first-child) {
        margin-top: .25em;
    }
}
