@use '../../01-mixins/mixins' as mixins;

/* ================
 BLOCK
================= */

.files {
    --scroll-bar-width: 0;
    --thumbnail-height: 100px;
    position: relative;
    max-width: 70ch;
}

/* ================
 ELEMENTS
================= */

.files__form-components {
    --gap-size: 1em;
    --color-1: var(--red-dark);
    --color-2: var(--red-light);
    font-size: .75em;
    color: var(--color-1);

    &:has(.files__error) {
        border-radius: var(--border-radius-base);
        border: 1px solid var(--color-1);
        background: var(--color-2);
        margin-block-end: var(--card-padding);
        padding: var(--gap-size);

        .files__error:last-child {
            margin-block-end: 0;
        }
    }
}

.files__error {
    display: list-item;
    list-style: disc outside;
    margin-inline-start: 2ch;

    &:last-child {
        margin-block-end: var(--card-padding);
    }
}

.files__upload-label {
    display: flex;
    align-items: center;
    gap: 1ch;
    flex-wrap: wrap;

    .archived &,
    .archived & ~ .form-row__hint {
        display: none;
    }
}

.files__thumbnails {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
    padding: .5rem;
    background: #F8F8F8 no-repeat center/3rem;
    border-radius: var(--border-radius-base);
    box-shadow: inset 0 0 1.5rem rgba(128, 128, 128, .05);
    min-height: calc(var(--thumbnail-height) + 2rem);
    height: calc(var(--thumbnail-height) + 2rem);
    overflow: auto;
    resize: vertical;
}

.files__actions {
    display: flex;
    justify-content: center;
    margin-inline: 1rem;
    transform: translateY(-100%);
    margin-bottom: calc((var(--target-size) - var(--form-grid-gap)) * -1);
    overflow: hidden;
    pointer-events: none;

    button {
        transition: all 150ms ease-in-out;
        transform: translateY(100%);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        &:not(:first-child) {
            border-top-left-radius: 0;
        }

        &:not(:last-child) {
            border-top-right-radius: 0;
        }
    }

    .archived & {
        display: none;
    }
}

.files__upload-error {
    margin-bottom: 1rem;
}

.files__fullscreen-toggle {
    position: absolute;
    top: calc(0 - var(--scroll-bar-width));
    right: var(--scroll-bar-width);
    z-index: 1;
    background: transparent;
    color: var(--gray-46);

    &:hover,
    &:focus {
        color: var(--gray-20);
    }
}

.files__fullscreen-toggle-icon {
    @include mixins.center;
    filter: drop-shadow(0 0 8px white);
}

.files__fullscreen-toggle-icon--off {
    display: none;
}

/* ================
 MODIFIER(S)
================= */

.files:where(:fullscreen, :-webkit-full-screen) {
    background: white;
    display: flex;
    flex-direction: column;
    padding: var(--card-padding);
    max-width: none;
    max-height: 100%;

    .files__dropzone-wrapper {
        flex: 1 1 100%;
    }

    .files__thumbnails {
        resize: none;
        height: 100%;
        margin-inline: calc(-1*var(--card-padding));
        margin-block-start: calc(-1*var(--card-padding));
    }

    .files__fullscreen-toggle {
        top: max(env(safe-area-inset-top), 0);
        background: rgba(255, 255, 255, .75);
    }

    .files__fullscreen-toggle-icon--on {
        display: none;
    }

    .files__fullscreen-toggle-icon--off {
        display: block;
    }
}

.files__thumbnails--expand {
    height: calc(2.5 * var(--thumbnail-height) + 3rem);
}

.files__thumbnails--empty {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCA0OCA0OCI+CiAgPHBhdGggZmlsbD0icmdiKDE3MCwgMTcwLCAxNzApIiBkPSJNOSAzVjBoNnYzSDlaTTAgOWgzdjZIMFY5Wm00OCAzOVYxNkgxNnYzMmgzMlpNMyAxOEgwdjZoM3YtNlptMjktOWgtM3Y2aDNWOVpNOSAzMHYzaDZ2LTNIOVptOC0yN1YwaDZ2M2gtNlpNMCAzM2g2di0zSDN2LTNIMHY2Wk0wIDZWMGg2djNIM3YzSDBabTMyLTZoLTZ2M2gzdjNoM1YwWiIvPgo8L3N2Zz4K");
}

.files__thumbnails--drop,
.files__thumbnails--processing {
    &::after {
        @include mixins.backdrop-blur(2px, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.95));
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        content: attr(data-drop-message);
        pointer-events: none;
    }
}

.files__thumbnails--processing {
    overflow: hidden;
}

.files__thumbnails--processing::after {
    content: attr(data-processing-message);
}

.files__actions--active {
    pointer-events: auto;

    button {
        transform: none;
    }
}

.files__dropzone-wrapper {
    position: relative;

    po-status-message {
        @include mixins.backdrop-blur(2px, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.95));
        position: absolute;
        inset: 0;
        padding: 2rem;
        z-index: 1;
    }
}
