html,
body {
    scroll-padding-top: calc(var(--header-height, 64px) + var(--card-padding, 1rem));
    scroll-behavior: smooth;
}

body {
    margin: 0;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    background: #FFFFFF url(images/20120216.gif) repeat fixed center 0;
}
