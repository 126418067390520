@import '~tinymce/skins/ui/oxide/skin.css';
@import '~tinymce/skins/content/default/content.css';

/* ================
 ELEMENTS
================= */

.rte {
    --toolbar-height: calc(var(--input-min-height) - 1px);
}

.rte__label {
    display: block;
    font-weight: 600;
    margin-block-end: .25em;
}

.rte__container {
    position: relative;
    min-height: 440px;
    display: flex;
    flex-direction: column;

    .form-row__hint {
        margin-top: auto;
    }
}

.rte__icon {
    top:calc(var(--toolbar-height) / 2);
    transform: translateY(-50%);
    z-index: 1;
}

/* ================
 TINYMCE SPECIFICS
================= */

.tox-tinymce {
    border: 1px solid var(--gray-46);
    border-radius: var(--border-radius-base);
    resize: vertical;
}

.tox {
    &:not(.tox-tinymce-inline) .tox-editor-header {
        min-height: var(--toolbar-height);
        border-bottom: 1px solid var(--gray-46);
        box-shadow: none;
        padding-inline-end: var(--input-right-padding);
        display: flex;
        align-items: center;
    }

    .tox-toolbar-overlord,
    .tox-toolbar__primary {
        background: none !important;
    }

    .tox-toolbar__group {
        --padding-inline: calc((var(--toolbar-height) - var(--target-size)) / 2);
        padding-inline: var(--padding-inline);
        display: flex;
        gap: calc(var(--padding-inline) / 2);

        &:not(:last-child) {
            border-right: 1px solid var(--gray-46) !important;
        }
    }

    .tox-tbtn {
        border-radius: var(--border-radius-base);
        width: auto;
        min-width: var(--target-size);
        height: auto;
        min-height: var(--target-size);
        padding: 0;
        margin: 0;
        cursor: pointer;

        &:hover,
        &:focus {
            background: var(--blue-lighter);
        }

        svg {
            fill: currentColor !important;
        }
    }

    .tox-tbtn--enabled {
        background: var(--blue) !important;
        color: #FFFFFF !important;
    }
}

.mce-content-body {
    &,
    *::before,
    *::after {
        box-sizing: border-box;
    }

    margin: 0;
    padding: var(--card-padding);
    background: none;
    display: block;
}

.mce-content-readonly {
    color: var(--gray-46);
    background: var(--gray-96);
}

.tox-tinymce:not(.tox-tinymce--ready) {
    background: var(--gray-96) !important;

    * {
        visibility: hidden;
    }

    & ~ .rte__icon {
        display: none;
    }
}


