/* ================
 SHADOWS
================= */

:root {
    --shadow-color: rgba(39, 49, 91, .12);
    --shadow-huge-blur: 24px;
    --shadow-huge: 0 4px var(--shadow-huge-blur) var(--shadow-color);
    --filter-shadow-huge: drop-shadow(0 4px var(--shadow-huge-blur) var(--shadow-color));
    --shadow-small: 0 2px 0 rgba(0, 0, 0, .08);
    --shadow-small-top: 0 -2px 0 rgba(0, 0, 0, .08);
}
