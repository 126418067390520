/* ================
 BLOCK
================= */

.compact-collection {
    --space: 1.25rem;
    --button-size: var(--target-size);
    --button-gap: 1ch;
}

/* ================
 ELEMENTS
================= */

.compact-collection__heading {
    margin-block-end: .9em;
}

.compact-collection__entry {
    &:last-of-type {
        margin-block-end: var(--space);
    }

    &:not(:last-of-type) {
       padding-block-end: var(--space);
    }

    & + & {
        padding-block-start: var(--space);
    }
}

.compact-collection__entry-content {
    display: flex;
    align-items: start;
    gap: var(--space);

    .form-grid {
        flex: 1 1 auto;
    }
}

.compact-collection__entry-buttons {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    gap: var(--button-gap);
    min-height: var(--input-min-height);
}

.compact-collection__button {
    display: inline-grid;
    place-items: center;
    width: var(--button-size);
    height: var(--button-size);
    border-radius: 50%;
    cursor: pointer;
}

.compact-collection__button-icon {
    pointer-events: none;
    max-width: 55%;
    max-height: 55%;
}

form:not(.did-submit) .compact-collection__error,
.did-submit .compact-collection__valid-entries-input:valid ~  .compact-collection__error {
    display: none;
}

/* ================
 MODIFIER(S)
================= */

.compact-collection--buttons-end .compact-collection__entry-buttons {
    align-self: end;
}

.compact-collection.set-size .compact-collection__entry-buttons {
    display: none;
}

.compact-collection__button[hidden] {
    display: inline-grid;
    visibility: hidden;
}

.compact-collection__button--add {
    background: var(--green-dark);
    color: white;
    visibility: hidden;

    &:where(:hover, :focus) {
        background: var(--green);
    }

    /*show add button on currently hovered/focussed entry OR on the last entry*/
    .compact-collection__entry:where(:last-of-type, :hover, :focus-within) & {
        visibility: visible;
    }
    .compact-collection__entry:where(:last-of-type, :hover, :focus-within) ~ .compact-collection__entry & {
        visibility: hidden;
    }
}

.compact-collection__button--delete {
    &:where(:hover, :focus) {
        color: var(--red);
    }
}
