/* ================
 BLOCK
================= */

.publication {
    &:not(:last-child) {
        margin-block-end: var(--card-padding);
    }

    &:disabled {
        .fieldset__heading {
            margin: 0;
        }

        .fieldset__content {
            display: none;
        }
    }
}

/* ================
 ELEMENTS
================= */

.publication__header {
    margin-block: 0;

    &:nth-child(2) {
        margin-block-start: -.28125rem;
    }

    &:last-child {
        margin-block-end: 0;
    }
}

.publication__heading,
.publication__violations-heading,
.publication__errors-heading{
    font-size: 1rem;
    margin: 0;
}

.publication__heading {
    font-weight: 600;

    &:not(:last-child) {
        margin-block-end: .4em;
    }

    .message & {
        line-height: 1.5;
        margin-block: 0 .25em;
    }
}

.publication__violations-heading,
.publication__errors-heading{
    font-weight: 500;
}

.publication__subheading {
    font-size: .875rem;
}

.publication__violations {
    margin-block-start: .75rem;
    color: var(--text-color);
}

.publication__violations ul {
    list-style-type: disc;
    margin-block: 0;
}

.publication__violations-section,
.publication__errors-section{
    &:not(:last-child) {
        padding-block-end: var(--card-padding);
    }
}

.publication__violations-item {
    font-size: .875rem;
}

.publication__violations-link {
    display: inline-grid;
    align-items: center;
    text-decoration: underline;
    padding-inline: 1ch;
    margin-inline: -1ch;
    border: 1px dotted transparent;

    &:focus {
        border-color: currentColor;
    }
}
