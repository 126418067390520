@use '../../00-variables/breakpoints' as breakpoints;

:where(.ground-lease, .cadastre) {
    --choice-span: 6;

    .choice {
        grid-column: auto / span var(--choice-span) !important;
        padding: 1em 0;
        border-bottom: 1px solid var(--gray-90);
    }

    .hide-row {
        display: none;
    }

    @media (min-width: breakpoints.$sm) {
        --choice-span: 3;
        .hide-row {
            display: block;
            visibility: hidden;
        }
    }

    @media (min-width: breakpoints.$md) {
        --choice-span: 6;
        .hide-row {
            display: none;
        }
    }

    @media (min-width: breakpoints.$lg) {
        --choice-span: 3;
        .hide-row {
            display: block;
            visibility: hidden;
        }
    }
}

.ground-lease-options {
    display: contents;

    legend {
        display: none;
    }

    .choices {
        display: contents;
    }
}
