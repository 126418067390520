/* ================
 BLOCK
================= */

.calendar-event {
    font-size: .875rem;

    & + & {
        border-block-start: 1px solid var(--gray-90);
        margin-block-start: 1em;
        padding-block-start: 1em;
    }
}

/* ================
 ELEMENTS
================= */

.calendar-event__link {
    text-decoration: none;
    color: currentColor;

    &:where(:hover, :focus) {
        text-decoration: underline;
        text-decoration-style: dotted;
        color: currentColor !important;
    }
}

.calendar-event__title {
    font-weight: 600;
    font-size: 1rem;
    margin-block-end: .5em;
}

.calendar-event__data-row {
    display: grid;
    grid: auto / 1em 1fr;
    gap: 1ch;

    & + & {
        margin-block-start: .25em;
    }
}

.calendar-event__data-row dt {
    display: contents;
}

.calendar-event__icon {
    display: block;
    width: 1em;
    height: 1em;
    margin-block-start: .25em;
}

@supports (transform: translateY(.5lh) translateY(-50%)) {
    .calendar-event__icon {
        margin-block-start: 0;
        transform: translateY(.5lh) translateY(-50%);
    }
}
