/* ================
 LEGACY
================= */

:root {
    --body-width: 1360px;
    --header-height: calc(2 * var(--target-size));
    --search-form-header-height: '0px';
    --filter-column-width: 320px;

    --gray-legacy: hsl(214, 10%, 13%);

    /* menu related stuff */
    --menu-font-size: .875rem;
}
