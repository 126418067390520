/* ================
 BLOCK
================= */

.dashboard-header {
    position: sticky;
    top: 0;
    z-index: 100;
}

.dashboard-header::before {
    --bg: #FFFFFF;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 33.333vw;
    background: var(--bg);
    box-shadow: -33.333vw 0 var(--bg), 33.333vw 0 var(--bg);
}

/* ================
 MODIFIER(S)
================= */

.dashboard-header--skeleton::before {
    background: linear-gradient(to bottom, #FFFFFF 50%, var(--blue-dark) 50%);
}

.dashboard-header--skeleton .dashboard-header__inner {
    position: relative;
    height: calc(2 * var(--target-size));
    background: linear-gradient(to right, var(--blue-dark) 60px, var(--blue-light) 60px 120px, var(--orange) 120px calc(120px + var(--target-size)), transparent calc(120px + var(--target-size)));
    background-size: 100% 50%;
    background-repeat: no-repeat;
}
