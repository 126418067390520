.wws-spec {
    display: grid;
    grid: auto / 1fr auto auto;
    container-type: inline-size;
    font-size: .875rem;
    margin: 0 var(--card-padding) calc(-1 * var(--card-padding));
}

.wws-spec--no-range {
    grid-template-columns: 1fr auto;
}

.wws-spec__header {
    grid-column: 1 / -1;
    margin-block-end: 1rem;

    h2,
    p {
        margin: 0;
    }
}

.wws-spec__row,
.wws-spec__subcategories {
    position: relative;
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: subgrid;
    align-items: center;
    padding-block: .125lh;
}

.wws-spec__subcategories {
    color: var(--gray-46);
    font-size: .75em;
    padding: .25em 1ch;
    padding-inline-end: 0;
}

.wws-spec__subcategory {
    margin-inline-start: 1ch;
}

.wws-spec__row--header,
.wws-spec__row--total {
    font-size: 1rem;
}

.wws-spec__row--header :first-child {
    text-align: left;
}

.wws-spec__row--total {
    font-weight: 600;
}

.wws-spec__category,
.wws-spec__subcategory {
    font-size: inherit;
    font-weight: inherit;
    margin: 0;
}

.wws-spec__points {
    display: contents;
}

.wws-spec__points-type::after {
    content: ':';
}

.wws-spec__points-value {
    text-align: right;
    font-variant-numeric: tabular-nums;
}

.wws-spec__footer {
    font-size: .75rem;
    margin: 1em 0 0;
    color: var(--gray-46);
}

@container (width <= 320px) {
    .wws-spec__row {
        grid-template-columns: 1fr auto;
        padding-block-end: 1em;
    }

    .wws-spec__row--header {
        display: none;
    }

    .wws-spec__category {
        grid-column: 1 / -1;
        font-size: 1rem;
        font-weight: 600;
    }

    .wws-spec__points {
        font-size: .875em;
    }

    .wws-spec__row--total .wws-spec__category {
        font-size: 1.125rem;
    }

    .wws-spec__subcategories {
        grid: auto / 1fr auto auto;
        column-gap: 1em;

        .wws-spec__points-type {
            display: none;
        }

        .wws-spec__points-value {
            display: flex;
            justify-content: space-between;
        }

        .wws-spec__points-value::before {
            content: 'min';
            margin-inline-end: 1ch;
        }

        .wws-spec__points-value--max::before {
            content: 'max';
        }

        .wws-spec__points--equal {
            .wws-spec__points-value--min {
                grid-column: 2 / -1;
                justify-self: end;

                &::before {
                    display: none;
                }
            }
            .wws-spec__points-value--max {
                display: none;
            }
        }
    }
}

@container (width > 320px) {
    .wws-spec {
        padding-inline-end: 1ch;
    }

    .wws-spec__row--header,
    .wws-spec__row--total {
        font-weight: 600;
        text-align: right;
    }

    .wws-spec__points-type {
        position: absolute;
        width: 1px !important;
        height: 1px !important;
        padding: 0 !important;
        margin: -1px !important;
        overflow: hidden !important;
        clip: rect(0,0,0,0) !important;
        white-space: nowrap !important;
        border: 0 !important;
    }

    .wws-spec__points-value {
        padding-inline-start: 1.5rem;
    }

    .wws-spec__row--total {
        margin-block-start: 2px;
    }

    .wws-spec__row--total .wws-spec__category {
        padding-inline-end: 1ch;
    }

    .wws-spec__row--total .wws-spec__category::after {
        content: ':';
    }

    .wws-spec__row--total .wws-spec__points-value {
        position: relative;
        box-shadow: 0 -2px currentColor;
    }

    .wws-spec__row--total .wws-spec__points-value:last-child::after {
        content: '+';
        font-weight: bold;
        position: absolute;
        inset-block-end: 100%;
        inset-inline-start: 100%;
        translate: .5ch calc(50% - 2px);
    }
}
