@use '../../00-variables/breakpoints' as breakpoints;

/* ================
 BLOCK
================= */

.form-grid {
    display: grid;
    grid: auto / repeat(6, 1fr);
    gap: var(--form-grid-gap);
    align-items: start;
    max-width: 70ch;
    grid-column: 1 / -1;

    @media screen and (max-width: 480px) {
        display: flex;
        flex-wrap: wrap;

        & > * {
            width: 100%;
            flex: 0 0 auto;
        }
    }
}

/* ================
 ELEMENTS
================= */

.form-grid__contents {
    display: contents;
}

.form-grid > :not([class*="form-grid"]),
.form-grid__element {
    grid-column: 1 / -1;
    margin-bottom: 0;
}

/* ================
 MODIFIER(S)
================= */

.form-grid--7-cols {
    grid-template-columns: repeat(7, 1fr);
    margin-right: calc(-1 * var(--target-size));
}


@each $name, $size in breakpoints.$breakpoints {

    @media screen and (max-width: #{$size}) {
        .form-grid__element--span-all#{"\\@"}until-#{$name} {
            grid-column: 1 / -1 !important;
        }

        @for $i from 1 through 7 {
            .form-grid__element--span-#{$i}#{"\\@"}until-#{$name} {
                grid-column-start: auto !important;
                grid-column-end: span $i !important;
            }

            .form-grid__element--start-#{$i}#{"\\@"}until-#{$name} {
                grid-column-start: $i !important;
            }

            .form-grid__element--end-#{$i}#{"\\@"}until-#{$name} {
                grid-column-end: $i !important;
            }
        }
    }

    @media screen and (min-width: #{$size - 1}) {
        .form-grid__element--span-all#{"\\@"}from-#{$name} {
            grid-column: 1 / -1 !important;
        }

        @for $i from 1 through 7 {
            .form-grid__element--span-#{$i}#{"\\@"}from-#{$name} {
                grid-column-start: auto !important;
                grid-column-end: span $i !important;
            }

            .form-grid__element--start-#{$i}#{"\\@"}from-#{$name} {
                grid-column-start: $i !important;
            }

            .form-grid__element--end-#{$i}#{"\\@"}from-#{$name} {
                grid-column-end: $i !important;
            }
        }
    }
}

@for $i from 1 through 7 {
    .form-grid__element--span-#{$i} {
        grid-column-end: span $i !important;
    }

    .form-grid__element--start-#{$i} {
        grid-column-start: $i !important;
    }

    .form-grid__element--end-#{$i} {
        grid-column-end: $i !important;
    }
}

.form-grid:has(.clearable-boolean) {
    row-gap: .25rem;
}
